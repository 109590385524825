import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Image,
  Input,
  InputGroup,
  Link,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import enterDarkImg from '../../../assets/enter-dark.svg';
import enterLightImg from '../../../assets/enter-light.svg';
import token1USD from '../../../assets/crypto/1USD.svg';

export function FeaturedAssetsPanel({
  assetName,
  assetImg,
  assetPrice,
  initialValue,
  transform,
}: React.PropsWithChildren<{
  assetName: string;
  assetImg: any;
  assetPrice: number;
  initialValue: number;
  transform: string;
}>) {
  const { colorMode } = useColorMode();

  const border = useColorModeValue(
    '1.5px solid hsla(240, 2%, 12%, 0.2) !important',
    '1.5px solid hsla(200, 43%, 99%, 0.2) !important'
  );

  // const borderInv = useColorModeValue(
  //   '1.5px solid hsla(200, 43%, 99%, 0.2)',
  //   '1.5px solid hsla(240, 2%, 12%, 0.2)'
  // );

  const flexBorder = useColorModeValue(
    '4px solid hsla(200, 43%, 99%, 1) !important',
    '4px solid hsla(240, 2%, 12%, 1) !important'
  );

  const tokenBorder = useColorModeValue(
    '1.5px solid hsla(200, 43%, 99%, 0.8)',
    '1.5px solid hsla(240, 2%, 12%, 0.8)'
  );

  const tokenBoxShadow = useColorModeValue(
    '0 0 0 1.5px hsla(240, 2%, 12%, 0.2)',
    '0 0 0 1.5px hsla(200, 43%, 99%, 0.2)'
  );

  // const tokenBorderInv = useColorModeValue(
  //   '4px solid hsla(240, 2%, 12%, 1)',
  //   '4px solid hsla(200, 43%, 99%, 1)'
  // );

  const boxShadowSmall = useColorModeValue(
    '0 0 0 2px hsla(240, 2%, 12%, 0.1) !important',
    '0 0 0 2px hsla(200, 43%, 99%, 0.1) !important'
  );

  // const boxShadowSmallInv = useColorModeValue(
  //   '0 0 0 2px hsla(200, 43%, 99%, 0.1)',
  //   '0 0 0 2px hsla(240, 2%, 12%, 0.1)'
  // );

  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorInv = useColorModeValue(
    'hsla(200, 43%, 99%, 1)',
    'hsla(240, 2%, 12%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const colorDimmedInv = useColorModeValue(
    'hsla(200, 43%, 99%, 0.65)',
    'hsla(240, 2%, 12%, 0.65)'
  );

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const bgInv = useColorModeValue('#1D1D1E', '#FAFCFD');

  const buttonBgInv = useColorModeValue(
    'linear-gradient(0deg, hsla(200, 43%, 99%, 1) 0%, hsla(200, 43%, 99%, 0.8) 100%)',
    'linear-gradient(0deg, hsla(240, 2%, 12%, 1) 0%, hsla(240, 2%, 12%, 0.8) 100%)'
  );

  const buttonBgHoverInv = useColorModeValue(
    'linear-gradient(0deg, hsla(200, 43%, 99%, 1) 0%, hsla(200, 43%, 99%, 1) 100%)',
    'linear-gradient(0deg, hsla(240, 2%, 12%, 1) 0%, hsla(240, 2%, 12%, 1) 100%)'
  );

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  const gradientBgInv = useColorModeValue(
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)'
  );

  const gradientGreenOrange = useColorModeValue(
    'linear-gradient(90deg, #6755FF 0%, #F7AB19 100%)',
    'linear-gradient(90deg, #6755FF 0%, #F7AB19 100%)'
  );

  const regexDec = /^\d*\.?$/,
    decimalChange = (event: any) => {
      if (event.key.length > 1 || regexDec.test(event.key)) return;
      event.preventDefault();
    };

  const [inputValue, setInputValue] = React.useState(initialValue);
  const [sliderValue, setSliderValue] = React.useState(30);

  const borrow1USD =
    (inputValue * assetPrice * sliderValue) / 100 < 1000
      ? ((inputValue * assetPrice * sliderValue) / 100).toFixed(2)
      : ((inputValue * assetPrice * sliderValue) / 100).toFixed(0);

  return (
    <>
      <Box w={'full'} m={'auto'} mt={['10px', '20px', '30px']}>
        <Grid
          w={'full'}
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
          ]}
          templateRows={[
            'repeat(2, auto)',
            'repeat(2, auto)',
            'repeat(1, auto)',
          ]}
          mt={['20px', '30px', '40px']}
          gap={'2'}
        >
          <Flex bg={bg} w={'full'}>
            <Flex
              flexDir={'column'}
              w={'full'}
              p={['24px', '32px', '48px']}
              border={flexBorder}
              boxShadow={boxShadowSmall}
              bg={gradientBg}
              color={color}
            >
              <Text fontSize={['14px', '16px', '18px']} fontWeight={'600'}>
                DEPOSIT
              </Text>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                py={['14px', '16px', '18px']}
                border={'1.5px solid transparent'}
                borderBottom={border}
              >
                <Image
                  src={assetImg}
                  alt={assetName}
                  border={tokenBorder}
                  boxShadow={tokenBoxShadow}
                  borderRadius={'full'}
                  h={['28px', '35px', '42px']}
                  mr={['8px', '12px', '16px']}
                />
                <FormControl
                  bg={'transparent'}
                  borderRadius={'0'}
                  w={'full'}
                  p={'0'}
                  m={'0'}
                >
                  <InputGroup h={'full'} w={'full'} p={'0'} m={'0'}>
                    <Input
                      value={inputValue}
                      onChange={(evt) =>
                        setInputValue(evt.target.valueAsNumber)
                      }
                      onKeyPress={decimalChange}
                      placeholder={'0'}
                      type={'number'}
                      inputMode={'numeric'}
                      step={'any'}
                      autoComplete={'off'}
                      autoCorrect={'off'}
                      spellCheck={'false'}
                      pattern={'^[0-9][.,]?[0-9]$'}
                      fontSize={['24px', '30px', '36px']}
                      fontWeight={'500'}
                      h={'full'}
                      w={'full'}
                      p={'0'}
                      m={'0'}
                      border={'none'}
                      borderRadius={'0'}
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
              <Flex flexDir={'column'} mt={['16px', '24px', '32px']}>
                <Text
                  fontSize={['12px', '14px', '16px']}
                  fontWeight={'600'}
                  color={colorDimmed}
                >
                  LOAN-TO-VALUE
                </Text>
                <Flex my={['8px', '10px', '12px']}>
                  <Text
                    fontSize={['16px', '20px', '24px']}
                    fontWeight={'500'}
                    color={color}
                    mx={'4px'}
                  >
                    {`${sliderValue} %`}
                  </Text>
                </Flex>
                <Flex flexDir={'column'}>
                  <Flex px={'2px'}>
                    <Slider
                      aria-label={'slider-ltv'}
                      defaultValue={sliderValue}
                      min={1}
                      max={60}
                      onChange={(val) => setSliderValue(val)}
                      pt={'12px !important'}
                      pb={'12px !important'}
                    >
                      <SliderTrack
                        bg={gradientGreenOrange}
                        h={'8px'}
                        borderRadius={'full'}
                      >
                        <SliderFilledTrack
                          bg={'transparent'}
                          value={sliderValue}
                        />
                      </SliderTrack>
                      <SliderThumb
                        boxSize={6}
                        // boxShadow={'none'}
                        // left={'calc(49.1525% - 12px) !important'}
                        transform={transform}
                        _focus={{ boxShadow: boxShadowSmall }}
                      ></SliderThumb>
                    </Slider>
                  </Flex>
                  <Flex
                    justifyContent={'space-between'}
                    mt={['4px', '5px', '6px']}
                  >
                    <Text
                      fontSize={['12px', '13px', '14px']}
                      fontWeight={'500'}
                      color={'#6755FF'}
                    >
                      LOWER RISK
                    </Text>
                    <Text
                      fontSize={['12px', '13px', '14px']}
                      fontWeight={'500'}
                      color={'#F7AB19'}
                    >
                      HIGHER RISK
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex bg={bgInv} w={'full'}>
            <Flex
              flexDir={'column'}
              w={'full'}
              p={['24px', '32px', '48px']}
              border={flexBorder}
              boxShadow={boxShadowSmall}
              bg={gradientBgInv}
              color={colorInv}
            >
              <Text fontSize={['14px', '16px', '18px']} fontWeight={'600'}>
                BORROW
              </Text>
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                py={['14px', '16px', '18px']}
                border={'1.5px solid transparent'}
                // borderBottom={borderInv}
              >
                <Image
                  src={token1USD}
                  // border={tokenBorder}
                  // boxShadow={tokenBoxShadow}
                  alt={'1USD'}
                  borderRadius={'full'}
                  h={['28px', '35px', '42px']}
                  mr={['8px', '12px', '16px']}
                />
                <FormControl
                  bg={'transparent'}
                  borderRadius={'0'}
                  w={'full'}
                  p={'0'}
                  m={'0'}
                  pointerEvents={'none'}
                >
                  <InputGroup
                    h={'full'}
                    w={'full'}
                    p={'0'}
                    m={'0'}
                    alignItems={'center'}
                  >
                    <Input
                      value={borrow1USD}
                      readOnly={true}
                      placeholder={'0'}
                      type={'number'}
                      inputMode={'numeric'}
                      step={'any'}
                      autoComplete={'off'}
                      autoCorrect={'off'}
                      spellCheck={'false'}
                      pattern={'^[0-9][.,]?[0-9]$'}
                      fontSize={['24px', '30px', '36px']}
                      fontWeight={'500'}
                      h={'full'}
                      w={'full'}
                      p={'0'}
                      m={'0'}
                      border={'none'}
                      borderRadius={'0'}
                    />
                  </InputGroup>
                </FormControl>
              </Flex>
              <Flex flexDir={'column'} mt={['16px', '24px', '32px']}>
                <Flex w={'full'} justifyContent={'space-between'}>
                  <Text
                    fontSize={['12px', '14px', '16px']}
                    fontWeight={'600'}
                    color={colorDimmedInv}
                  >
                    BORROW FEE
                  </Text>
                  <Text
                    fontSize={['12px', '14px', '16px']}
                    fontWeight={'600'}
                    color={colorDimmedInv}
                  >
                    INTEREST RATE
                  </Text>
                </Flex>
                <Flex my={['8px', '10px', '12px']}>
                  <Flex w={'full'} justifyContent={'space-between'}>
                    <Text
                      fontSize={['16px', '20px', '24px']}
                      fontWeight={'500'}
                      color={colorInv}
                      mx={'4px'}
                    >
                      0 %
                    </Text>

                    <Text
                      fontSize={['16px', '20px', '24px']}
                      fontWeight={'500'}
                      color={colorInv}
                      mx={'4px'}
                    >
                      3 %
                    </Text>
                  </Flex>
                </Flex>
                <Flex flexDir={'column'}>
                  <Flex px={'2px'}></Flex>
                  <Flex
                    justifyContent={'space-between'}
                    mt={['4px', '5px', '6px']}
                  >
                    <Link as={ReactLink} to={'/borrow'} w={'full'}>
                      <Button
                        variant={'primary'}
                        w={'full'}
                        justifyContent={'space-between'}
                        h={['40px', '48px', '56px']}
                        fontSize={['12px', '12px', '14px', '16px', '16px']}
                        // mx={['4px', '8px', '16px']}
                        mx={['0']}
                        pr={'0'}
                        // cursor={'not-allowed'}
                        color={color}
                        bg={buttonBgInv}
                        _hover={{ bg: buttonBgHoverInv, fontWeight: '600' }}
                      >
                        <Text
                          textAlign={'left'}
                          w={['45px', '45px', '55px', '60px', '60px']}
                        >
                          GET STARTED
                        </Text>
                        {colorMode === 'dark' ? (
                          <Image
                            h={'60px'}
                            ml={['18px', '48px', '64px', '64px', '64px']}
                            mr={'-6px'}
                            mb={'10px'}
                            src={enterDarkImg}
                          />
                        ) : (
                          <Image
                            h={'60px'}
                            ml={['18px', '48px', '64px', '64px', '64px']}
                            mr={'-6px'}
                            mb={'10px'}
                            src={enterLightImg}
                          />
                        )}
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}
