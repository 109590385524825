import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
// Supports weights 300-700
import '@fontsource-variable/spline-sans-mono';

const Container = {
  baseStyle: {},
  variants: {
    token: (props: any) => ({
      position: 'relative',
      maxW: 'full',
      h: 'full',
      bg: mode(
        'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
        'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
      )(props),
      p: ['12px', '16px', '20px'],
      borderRadius: '0',
      boxShadow: mode(
        'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
        'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
      )(props),
      _before: {
        content: '""',
        position: 'absolute',
        top: '-2px',
        bottom: '-2px',
        left: '-2px',
        right: '-2px',
        // background: 'linear-gradient(90deg, #6755FF 0%, #F7AB19 100%)',
        borderRadius: '0',
        zIndex: '-2',
      },
      _after: {
        content: '""',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        bg: mode('#FAFCFD', '#1D1D1E')(props),
        borderRadius: '0',
        zIndex: 'var(--chakra-zIndices-hide)',
      },
    }),
  },
};

const Link = {
  baseStyle: {},
  variants: {
    basic: (props: any) => ({
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'sm',
      fontWeight: '400',
      color: mode(
        'hsla(240, 2%, 12%, 0.65)',
        'hsla(200, 43%, 99%, 0.65)'
      )(props),
      bg: 'transparent',
      border: 'none',
      borderRadius: '0',
      textDecoration: 'none',
      _hover: {
        color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
        bg: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: mode(
          'hsla(240, 2%, 12%, 0.8)',
          'hsla(200, 43%, 99%, 0.8)'
        )(props),
      },
    }),
    header: (props: any) => ({
      fontSize: ['8px', '12px'],
      fontWeight: '400',
      color: mode('hsla(240, 2%, 12%, 0.8)', 'hsla(200, 43%, 99%, 0.8)')(props),
      _hover: {
        fontWeight: '500',
        color: mode('#1D1D1E', '#FAFCFD')(props),
      },
      _focus: {
        boxShadow: 'none',
      },
    }),
    headerActive: (props: any) => ({
      fontSize: ['8px', '12px'],
      fontWeight: '600',
      color: mode('#1D1D1E', '#FAFCFD')(props),

      _focus: {
        boxShadow: 'none',
      },
    }),
  },
};

const Button = {
  baseStyle: {},
  variants: {
    link: (props: any) => ({
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'sm',
      fontWeight: '400',
      color: mode(
        'hsla(240, 2%, 12%, 0.65)',
        'hsla(200, 43%, 99%, 0.65)'
      )(props),
      bg: 'transparent',
      border: 'none',
      borderRadius: '0',
      textDecoration: 'none',
      _hover: {
        color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
        bg: 'transparent',
        textDecoration: 'none',
      },
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        color: mode(
          'hsla(240, 2%, 12%, 0.8)',
          'hsla(200, 43%, 99%, 0.8)'
        )(props),
      },
    }),
    primary: (props: any) => ({
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      borderRadius: '0',

      fontWeight: '400',
      color: mode('#FAFCFD', '#1D1D1E')(props),

      bg: mode(
        'linear-gradient(0deg, hsla(240, 2%, 12%, 1) 0%, hsla(240, 2%, 12%, 0.8) 100%)',
        'linear-gradient(0deg, hsla(200, 43%, 99%, 1) 0%, hsla(200, 43%, 99%, 0.8) 100%)'
      )(props),

      _hover: {
        fontWeight: '600',

        bg: mode(
          'linear-gradient(0deg, hsla(240, 2%, 12%, 1) 0%, hsla(240, 2%, 12%, 1) 100%)',
          'linear-gradient(0deg, hsla(200, 43%, 99%, 1) 0%, hsla(200, 43%, 99%, 1) 100%)'
        )(props),
      },
      _focus: {
        boxShadow: 'none',
      },

      _disabled: {
        pointerEvents: 'none',
      },
    }),
    secondary: (props: any) => ({
      d: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0',

      fontWeight: '400',
      color: mode(
        'hsla(240, 2%, 12%, 0.65)',
        'hsla(200, 43%, 99%, 0.65)'
      )(props),

      bg: mode('hsla(240, 2%, 12%, 0.04)', 'hsla(200, 43%, 99%, 0.04)')(props),

      border: mode(
        '1px solid hsla(240, 2%, 12%, 0.1)',
        '1px solid hsla(200, 43%, 99%, 0.1)'
      )(props),

      _hover: {
        fontWeight: '500',

        color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),

        bg: mode(
          'hsla(240, 2%, 12%, 0.08)',
          'hsla(200, 43%, 99%, 0.08)'
        )(props),

        border: mode(
          '1px solid hsla(240, 2%, 12%, 0.2)',
          '1px solid hsla(200, 43%, 99%, 0.2)'
        )(props),
      },
      _focus: {
        boxShadow: 'none',
      },
      _active: {
        border: mode(
          '1px solid hsla(240, 2%, 12%, 0.3)',
          '1px solid hsla(200, 43%, 99%, 0.3)'
        )(props),
        boxShadow: mode(
          'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)',
          'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)'
        )(props),
      },
    }),

    disabled: (props: any) => ({
      borderRadius: '0 !important',
      p: '16px !important',
      color: mode(
        'hsla(240, 2%, 12%, 1) !important',
        'hsla(200, 43%, 99%, 1) !important'
      )(props),
      bg: mode(
        'hsla(240, 2%, 12%, 0.4) !important',
        'hsla(200, 43%, 99%, 0.4) !important'
      )(props),
      _hover: {
        bg: mode(
          'hsla(240, 2%, 12%, 0.4) !important',
          'hsla(200, 43%, 99%, 0.4) !important'
        )(props),
      },
    }),
  },
};

const Text = {
  baseStyle: {},
  variants: {
    h200: {
      fontSize: '18px',
      fontWeight: '400',
    },
    h300: {
      fontSize: '16px',
      fontWeight: '400',
    },
    h400: {
      fontSize: '14px',
      fontWeight: '400',
    },
    bodyExtraSmall: {
      fontSize: '12px',
      fontWeight: '400',
    },
    bodySmall: {
      fontSize: '14px',
      fontWeight: '400',
    },
    bodyMedium: {
      fontSize: '16px',
      fontWeight: '400',
    },
    bodyLarge: {
      fontSize: '18px',
      fontWeight: '500',
    },
    bodyHuge: {
      fontSize: '22px',
      fontWeight: '500',
    },
    bodyExtraLarge: {
      fontSize: '42px',
      fontWeight: '600',
    },
  },
};

const Tabs = {
  baseStyle: {},
  variants: {
    main: (props: any) => ({
      tab: {
        borderRadius: '0',
        fontSize: '14px',
        fontWeight: '400',
        p: ['10px 16px', '10px 20px'],
        color: mode(
          'hsla(240, 2%, 12%, 0.65)',
          'hsla(200, 43%, 99%, 0.65)'
        )(props),
        bg: mode(
          'hsla(240, 2%, 12%, 0.02)',
          'hsla(200, 43%, 99%, 0.02)'
        )(props),
        boxShadow: mode(
          'inset 0 -1px 0 0 hsla(240, 2%, 12%, 0.2)',
          'inset 0 -1px 0 0 hsla(200, 43%, 99%, 0.2)'
        )(props),
        _hover: {
          color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.04)',
            'hsla(200, 43%, 99%, 0.04)'
          )(props),
          boxShadow: mode(
            'inset 0 -1px 0 0 hsla(240, 2%, 12%, 0.4)',
            'inset 0 -1px 0 0 hsla(200, 43%, 99%, 0.4)'
          )(props),
        },
        _focus: {
          boxShadow: 'none',
        },
        _selected: {
          fontWeight: '600',
          color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.08)',
            'hsla(200, 43%, 99%, 0.08)'
          )(props),
          // outline: mode('thick double #1D1D1E', 'thick double #FAFCFD')(props),
          boxShadow: mode(
            'inset 0 -2px 0 0 hsla(240, 2%, 12%, 1)',
            'inset 0 -2px 0 0 hsla(200, 43%, 99%, 1)'
          )(props),
        },
      },
    }),
    primary: (props: any) => ({
      tablist: {
        borderBottom: '1px solid',
        borderColor: mode(
          'hsla(240, 2%, 12%, 0.4)',
          'hsla(200, 43%, 99%, 0.4)'
        )(props),
      },
      tabpanel: {
        p: '0',
        mt: '18px',
      },
      tab: {
        w: 'full',
        p: ['10px 16px', '10px 20px'],
        fontSize: ['12px', '14px', '16px'],
        fontWeight: '400',
        color: mode(
          'hsla(240, 2%, 12%, 0.65)',
          'hsla(200, 43%, 99%, 0.65)'
        )(props),
        bg: mode(
          'hsla(240, 2%, 12%, 0.02)',
          'hsla(200, 43%, 99%, 0.02)'
        )(props),
        _hover: {
          color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.04)',
            'hsla(200, 43%, 99%, 0.04)'
          )(props),
        },
        _focus: {
          boxShadow: 'none',
        },
        _selected: {
          position: 'relative',
          fontWeight: '600',
          color: mode('hsla(240, 2%, 12%, 1)', 'hsla(200, 43%, 99%, 1)')(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.08)',
            'hsla(200, 43%, 99%, 0.08)'
          )(props),
          _after: {
            content: '""',
            position: 'absolute',
            bottom: '-1px',
            w: 'full',
            h: '2px',
            bg: mode('#1D1D1E', '#FAFCFD')(props),
          },
        },
      },
    }),
  },
};

const Input = {
  baseStyle: {},
  variants: {
    percentage: (props: any) => ({
      field: {
        borderRadius: '0',
        bg: mode(
          'hsla(240, 2%, 12%, 0.08)',
          'hsla(200, 43%, 99%, 0.08)'
        )(props),
        textAlign: 'right',
        fontWeight: '400',
        p: '0 36px 0 16px',
      },
    }),
  },
};

const Table = {
  baseStyle: {},
  variants: {
    dashboard: (props: any) => ({
      table: {
        w: 'full',
        m: 'auto',
        borderCollapse: 'separate',
        borderSpacing: '0 8px',
      },
      thead: {
        tr: {
          td: {
            fontSize: '12px',
            color: mode(
              'hsla(240, 2%, 12%, 0.65)',
              'hsla(200, 43%, 99%, 0.65)'
            )(props),
            textTransform: 'uppercase',
            pt: '16px',
            pb: '24px',
            borderTop: '1px solid',
            borderColor: mode(
              'hsla(240, 2%, 12%, 0.4)',
              'hsla(200, 43%, 99%, 0.4)'
            )(props),
            _first: {
              div: {
                justifyContent: 'start',
              },
            },
            div: {
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        },
        _first: {
          transform: 'translateY(16px)',
        },
      },
      tbody: {
        /* Because the table uses tr as a link */
        a: {
          position: 'relative',
          bg: mode(
            'hsla(240, 2%, 12%, 0.04)',
            'hsla(200, 43%, 99%, 0.04)'
          )(props),
          _hover: {
            bg: mode(
              'hsla(240, 2%, 12%, 0.08)',
              'hsla(200, 43%, 99%, 0.08)'
            )(props),
            boxShadow: mode(
              'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)',
              'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)'
            )(props),
          },
          _active: {
            boxShadow: mode(
              'inset 0 0 0 2px hsla(240, 2%, 12%, 0.4)',
              'inset 0 0 0 2px hsla(200, 43%, 99%, 0.4)'
            )(props),
          },
          _after: {
            content: '""',
            position: 'absolute',
            borderRadius: '0',
            boxSizing: 'border-box',
            boxShadow: mode(
              'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
              'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
            )(props),
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            // zIndex: 'var(--chakra-zIndices-hide)',
          },
          td: {
            fontSize: '18px',
            bg: mode(
              'linear-gradient(0deg, hsla(240, 2%, 12%, 0.02) 0%, hsla(240, 2%, 12%, 0.01) 100%)',
              'linear-gradient(0deg, hsla(200, 43%, 99%, 0.02) 0%, hsla(200, 43%, 99%, 0.01) 100%)'
            )(props),
            _first: {
              borderLeftRadius: '0',
              div: {
                justifyContent: 'start',
              },
            },
            _last: {
              borderRightRadius: '0',
            },
            div: {
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              div: {
                // h: '24px',
              },
            },
          },
        },
        tr: {
          position: 'relative',
          bg: mode(
            'hsla(240, 2%, 12%, 0.04)',
            'hsla(200, 43%, 99%, 0.04)'
          )(props),
          _hover: {
            bg: mode(
              'hsla(240, 2%, 12%, 0.08)',
              'hsla(200, 43%, 99%, 0.08)'
            )(props),
            boxShadow: mode(
              'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)',
              'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)'
            )(props),
          },
          _active: {
            boxShadow: mode(
              'inset 0 0 0 2px hsla(240, 2%, 12%, 0.4)',
              'inset 0 0 0 2px hsla(200, 43%, 99%, 0.4)'
            )(props),
          },
          _after: {
            content: '""',
            position: 'absolute',
            borderRadius: '0',
            boxSizing: 'border-box',
            boxShadow: mode(
              'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
              'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
            )(props),
            top: '0',
            left: '0',
            bottom: '0',
            right: '0',
            // zIndex: 'var(--chakra-zIndices-hide)',
          },
          td: {
            fontSize: '18px',
            bg: mode(
              'linear-gradient(0deg, hsla(240, 2%, 12%, 0.02) 0%, hsla(240, 2%, 12%, 0.01) 100%)',
              'linear-gradient(0deg, hsla(200, 43%, 99%, 0.02) 0%, hsla(200, 43%, 99%, 0.01) 100%)'
            )(props),
            _first: {
              borderLeftRadius: '0',
              div: {
                justifyContent: 'start',
              },
            },
            _last: {
              borderRightRadius: '0',
            },
            div: {
              d: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              div: {
                // h: '24px',
              },
            },
          },
        },
      },
    }),
  },
};

export const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
  fonts: {
    heading: 'Spline Sans Mono Variable',
    body: 'Spline Sans Mono Variable',
  },
  global: (props: any) => ({
    accentColor: mode('#FF6600', '#00FF66')(props),
  }),
  zIndices: {
    header: 100,
  },
  styles: {
    global: (props: any) => ({
      html: { h: 'full', w: 'full', m: '0', p: '0' },
      body: {
        fontFamily: 'Spline Sans Mono Variable',
        // lineHeight: '1',
        position: 'relative',
        h: 'full',
        w: 'full',
        bg: mode('#FAFCFD', '#1D1D1E')(props),
        fontSize: 'full',
        color: mode('#1D1D1E', '#FAFCFD')(props),
        border: '0',
        m: '0',
        p: '0',
      },
      '*': {
        boxSizing: 'border-box',
        transition: 'none !important',
      },
      img: {
        imageRendering: 'pixelated',
      },
      a: {
        textDecoration: 'none !important',
        _focus: {
          boxShadow: 'none !important',
        },
      },
      input: {
        _focus: {
          boxShadow: 'none !important',
          borderColor: mode('#1D1D1E !important', '#FAFCFD !important')(props),
        },
        _placeholder: {
          color: mode(
            'hsla(240, 2%, 12%, 0.65)',
            'hsla(200, 43%, 99%, 0.65)'
          )(props),
        },
      },
      '.css-k008qs': {
        alignItems: 'center',
      },
      '.chakra-slider__thumb': {
        // left: 'calc(49.1525% - 12px) !important',
      },
      '.chakra-modal__close-btn': {
        borderRadius: '0 !important',
        top: '15px !important',
        right: '15px !important',
        color: mode('#1D1D1E !important', '#FAFCFD !important')(props),
        _hover: {
          color: mode(
            'hsla(240, 2%, 12%, 0.65) !important',
            'hsla(200, 43%, 99%, 0.65) !important'
          )(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.08) !important',
            'hsla(200, 43%, 99%, 0.08) !important'
          )(props),
        },
        _focus: {
          boxShadow: 'none !important',
        },
        _active: {
          color: mode(
            'hsla(240, 2%, 12%, 0.8) !important',
            'hsla(200, 43%, 99%, 0.8) !important'
          )(props),
          bg: mode(
            'hsla(240, 2%, 12%, 0.08) !important',
            'hsla(200, 43%, 99%, 0.08) !important'
          )(props),
        },
      },
      '#WEB3_CONNECT_MODAL_ID': {
        '.web3modal-modal-lightbox': {
          bg: mode(
            'hsla(240, 2%, 12%, 0.1) !important',
            'hsla(200, 43%, 99%, 0.1) !important'
          )(props),
        },
        div: {
          zIndex: 'var(--chakra-zIndices-tooltip)',
          div: {
            div: {
              borderRadius: '0',

              '.web3modal-provider-wrapper': {
                borderRadius: '0',
                p: '3px',
                bg: mode('#FAFCFD', '#1D1D1E')(props),
                border: mode(
                  '1px solid hsla(240, 2%, 12%, 0.2)',
                  '1px solid hsla(200, 43%, 99%, 0.2)'
                )(props),
                _first: {
                  borderBottom: mode(
                    '0.5px solid hsla(240, 2%, 12%, 0.2)',
                    '0.5px solid hsla(200, 43%, 99%, 0.2)'
                  )(props),
                },
                _last: {
                  borderTop: mode(
                    '0.5px solid hsla(240, 2%, 12%, 0.2)',
                    '0.5px solid hsla(200, 43%, 99%, 0.2)'
                  )(props),
                },
              },

              '.web3modal-provider-container': {
                borderRadius: '0',
                p: ['16px', '32px', '32px'],
                bg: mode('#FAFCFD', '#1D1D1E')(props),

                _hover: {
                  bg: mode(
                    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
                    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
                  )(props),

                  boxShadow: mode(
                    'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
                    'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
                  )(props),
                },
              },

              '.web3modal-provider-icon': {
                h: ['36px !important', '42px !important', '48px !important'],
                w: ['36px !important', '42px !important', '48px !important'],
              },

              '.web3modal-provider-name': {
                mt: ['12px', '16px', '18px'],
                fontSize: ['16px', '22px', '24px'],
                color: mode('#1D1D1E', '#FAFCFD')(props),
              },

              '.web3modal-provider-description': {
                fontSize: ['10px', '16px', '18px'],
                color: mode(
                  'hsla(240, 2%, 12%, 0.65)',
                  'hsla(200, 43%, 99%, 0.65)'
                )(props),
              },
            },
          },
        },
      },
      '.walletconnect-modal__mobile__toggle': {
        color: '#1D1D1E',
      },
      '.walletconnect-search__input': {
        color: '#1D1D1E',
        fontSize: ['11px !important', '12px !important', '13px !important'],
        fontWeight: '500 !important',
        _placeholder: {
          color: 'hsla(240, 2%, 12%, 0.65) !important',
        },
      },
      '.walletconnect-connect__button__text': {
        fontSize: ['10px !important', '11px !important', '12px !important'],
        lineHeight: '1 !important',
      },
    }),
  },
  components: {
    Button,
    Container,
    Input,
    Link,
    Table,
    Tabs,
    Text,
  },
});
