import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorMode,
  // useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import enterLightImg from '../../../assets/enter-light.svg';
import enterDarkImg from '../../../assets/enter-dark.svg';
import { FontAnimation } from './FontAnimation';

export function NotFoundContent() {
  useEffect(() => {
    const onPageLoad = () => {
      FontAnimation();
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const { colorMode } = useColorMode();

  // const colorDimmed = useColorModeValue(
  //   'hsla(240, 2%, 12%, 0.65)',
  //   'hsla(200, 43%, 99%, 0.65)'
  // );

  return (
    <>
      <Box
        d={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        w={'full'}
        maxW={'90vw'}
        my={'0'}
        mx={'auto'}
      >
        <Text id={'title'}>404</Text>
      </Box>

      <Box w={'90vw'} maxW={'640px'} my={'0'} mx={'auto'}>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['16px', '18px', '20px', '22px', '24px']}
            fontWeight={'400'}
          >
            THE PAGE YOU ARE LOOKING FOR CANNOT BE FOUND
          </Text>
        </Flex>
      </Box>

      <Box
        w={'90vw'}
        maxW={'640px'}
        m={'auto'}
        mt={'40px'}
        mb={{ base: '70px', xl: '40px' }}
      >
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          w={'max-content'}
          m={'auto'}
        >
          <Link to={'/'}>
            <Button
              variant={'primary'}
              fontSize={['12px', '12px', '14px', '16px', '16px']}
              pr={'0'}
            >
              GO BACK HOME
              {colorMode === 'light' ? (
                <Image h={'60px'} ml={'30px'} mb={'10px'} src={enterDarkImg} />
              ) : (
                <Image h={'60px'} ml={'30px'} mb={'10px'} src={enterLightImg} />
              )}
            </Button>
          </Link>
        </Flex>
      </Box>
    </>
  );
}
