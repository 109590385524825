import { ArrowBackIcon } from '@chakra-ui/icons';
import { HStack, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export function BackButton() {
  const navigate = useNavigate();

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const [isHovered, setIsHovered] = React.useState(false);

  function enter() {
    setIsHovered(true);
  }
  function leave() {
    setIsHovered(false);
  }

  return (
    <HStack
      spacing={'8px'}
      // onClick={() => navigate(-1)}
      onClick={() => navigate('/borrow')}
      cursor={'pointer'}
      h={'full'}
      py={'8px'}
      onMouseEnter={enter}
      onMouseLeave={leave}
    >
      <ArrowBackIcon
        w={'20px'}
        h={'20px'}
        color={isHovered ? color : colorDimmed}
      />
      <Text variant={'bodySmall'} color={isHovered ? color : colorDimmed}>
        {/* {'<< BACK'} */}
        BACK
      </Text>
    </HStack>
  );
}
