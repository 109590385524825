import * as React from 'react';
import { Tooltip, useColorModeValue } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export default function CustomTooltip({ label }: { label: React.ReactNode }) {
  const [isLabelOpen, setIsLabelOpen] = React.useState(false);

  const border = useColorModeValue('1px solid #1D1D1E', '1px solid #FAFCFD');

  const boxShadow = useColorModeValue(
    'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)',
    'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)'
  );

  const invColor = useColorModeValue('#FAFCFD', '#1D1D1E');

  const invBg = useColorModeValue('#1D1D1E', '#FAFCFD');

  return (
    <Tooltip
      hasArrow
      label={label}
      isOpen={isLabelOpen}
      placement={'right-end'}
      bg={invBg}
      boxShadow={boxShadow}
      border={border}
      borderRadius={'0'}
      color={invColor}
      p={'8px 12px'}
      textAlign={'center'}
      w={'max-content'}
      maxW={'100vw'}
      fontSize={['10px', '12px', '14px']}
    >
      <InfoIcon
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
      />
    </Tooltip>
  );
}
