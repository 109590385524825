import {
  Box,
  Flex,
  // Image,
  // Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import * as React from 'react';
import { default as arbitrum } from '../../../assets/crypto/ARB.svg';
import { default as avalanche } from '../../../assets/crypto/AVAX.svg';
import { default as bnbChain } from '../../../assets/crypto/BNB.svg';
// import wrappedBitcoin from '../../../assets/crypto/WBTC.svg';
import bitcoin from '../../../assets/crypto/BTC.svg';
import chainge from '../../../assets/crypto/CHNG.svg';
import { default as ethereum } from '../../../assets/crypto/ETH.svg';
import { default as fusion } from '../../../assets/crypto/FSN.svg';
import { default as polygon } from '../../../assets/crypto/MATIC.svg';
import { default as optimism } from '../../../assets/crypto/OP.svg';
import { FeaturedAssetsNetwork } from './FeaturedAssetsNetwork';
import { FeaturedAssetsPanel } from './FeaturedAssetsPanel';
import { FeaturedAssetTab } from './FeaturedAssetTab';

export function FeaturedAssets() {
  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const [priceBTC, setPriceBTC] = React.useState<number>(0);
  const [priceETH, setPriceETH] = React.useState<number>(0);
  const [priceFSN, setPriceFSN] = React.useState<number>(0);
  const [priceCHNG, setPriceCHNG] = React.useState<number>(0);

  const GetCoinPrices = () => {
    const getPriceBTC = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=BTC'
        );
        const data = response.data;
        setPriceBTC(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceBTC();
      const interval = setInterval(() => {
        getPriceBTC();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceETH = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=ETH'
        );
        const data = response.data;
        setPriceETH(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceETH();
      const interval = setInterval(() => {
        getPriceETH();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceFSN = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=FSN'
        );
        const data = response.data;
        setPriceFSN(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceFSN();
      const interval = setInterval(() => {
        getPriceFSN();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceCHNG = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=CHNG'
        );
        const data = response.data;
        setPriceCHNG(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceCHNG();
      const interval = setInterval(() => {
        getPriceCHNG();
      }, 10000);

      return () => clearInterval(interval);
    }, []);
  };

  GetCoinPrices();

  return (
    <>
      <Box
        w={'90vw'}
        maxW={['375px', '450px', '525px', '600px', '700px']}
        m={'auto'}
        mt={['60px', '80px', '100px']}
      >
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['16px', '20px', '24px', '28px', '32px']}
            fontWeight={'700'}
            color={color}
            mb={['6px', '8px', '10px']}
          >
            FEATURED SPOT ASSETS
          </Text>
          <Text
            fontSize={['10px', '12px', '14px', '16px', '18px']}
            fontWeight={'400'}
            color={colorDimmed}
          >
            Borrow 1USD against a variety of different collateral types, like
            Spot, Futures and Liquidity Provider tokens.
          </Text>
        </Flex>
      </Box>
      <Box
        mt={['16px !important', '20px !important', '24px !important']}
        maxW={'800px'}
        m={'auto'}
      >
        <Tabs variant={'primary'}>
          <TabList
            overflowY={'hidden'}
            overflowX={'scroll'}
            css={{
              '&::-webkit-scrollbar': {
                height: '0 !important',
              },
              '&::-webkit-scrollbar-track': {
                height: '0 !important',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent !important',
                borderRadius: '0 !important',
              },
            }}
          >
            <FeaturedAssetsNetwork name={'Fusion'} img={fusion} />
            <FeaturedAssetsNetwork
              name={'Ethereum'}
              img={ethereum}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
            <FeaturedAssetsNetwork
              name={'BNB Chain'}
              img={bnbChain}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
            <FeaturedAssetsNetwork
              name={'Arbitrum'}
              img={arbitrum}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
            <FeaturedAssetsNetwork
              name={'Polygon'}
              img={polygon}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
            <FeaturedAssetsNetwork
              name={'Optimism'}
              img={optimism}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
            <FeaturedAssetsNetwork
              name={'Avalanche'}
              img={avalanche}
              opacity={'0.65'}
              pointerEvents={'none'}
            />
          </TabList>
        </Tabs>
      </Box>
      <Box
        maxW={'1280px'}
        m={'auto'}
        mt={['16px !important', '20px !important', '24px !important']}
      >
        <Tabs>
          <TabList
            overflowY={'hidden'}
            overflowX={'scroll'}
            css={{
              '&::-webkit-scrollbar': {
                height: '0 !important',
              },
              '&::-webkit-scrollbar-track': {
                height: '0 !important',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'transparent !important',
                borderRadius: '0 !important',
              },
            }}
            border={'none'}
            boxShadow={'none'}
            paddingBottom={'2px'}
          >
            <FeaturedAssetTab
              ticker={'BTC'}
              name={'Bitcoin'}
              img={bitcoin}
              // ticker={'WBTC'}
              // name={'Wrapped Bitcoin'}
              // img={wrappedBitcoin}
            />
            <FeaturedAssetTab ticker={'ETH'} name={'Ether'} img={ethereum} />
            <FeaturedAssetTab ticker={'FSN'} name={'Fusion'} img={fusion} />
            <FeaturedAssetTab ticker={'CHNG'} name={'Chainge'} img={chainge} />
          </TabList>

          <TabPanels>
            <TabPanel p={'0'}>
              <FeaturedAssetsPanel
                assetName={'Bitcoin'}
                assetImg={bitcoin}
                // assetName={'Wrapped Bitcoin'}
                // assetImg={wrappedBitcoin}
                assetPrice={priceBTC}
                initialValue={10}
                transform={'translate(0, -50%) !important'}
              />
            </TabPanel>
            <TabPanel p={'0'}>
              <FeaturedAssetsPanel
                assetName={'Ether'}
                assetImg={ethereum}
                assetPrice={priceETH}
                initialValue={100}
                transform={'translate(-12px, -50%) !important'}
              />
            </TabPanel>
            <TabPanel p={'0'}>
              <FeaturedAssetsPanel
                assetName={'Fusion'}
                assetImg={fusion}
                assetPrice={priceFSN}
                initialValue={250000}
                transform={'translate(-12px, -50%) !important'}
              />
            </TabPanel>
            <TabPanel p={'0'}>
              <FeaturedAssetsPanel
                assetName={'Chainge'}
                assetImg={chainge}
                assetPrice={priceCHNG}
                initialValue={750000}
                transform={'translate(-12px, -50%) !important'}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
}
