import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export const ConfirmPositionModal = ({
  title,
  isOpen,
  onClose,
  confirm,
  dangerous,
  body,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  body: { title: any; value: any }[];
  confirm: any;
  dangerous: boolean;
}) => {
  function depositBorrow() {
    confirm();
    onClose();
  }

  const border = useColorModeValue('1px solid #FAFCFD', '1px solid #1D1D1E');

  const boxShadow = useColorModeValue(
    'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)',
    'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)'
  );

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  const bgOpacity = useColorModeValue(
    'hsla(240, 2%, 12%, 0.08)',
    'hsla(200, 43%, 99%, 0.08)'
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={true}>
        <ModalOverlay />
        <ModalContent borderRadius={'0'} bg={bg} border={'none'}>
          <Box bg={gradientBg} boxShadow={boxShadow} border={border}>
            <ModalHeader fontSize={'lg'} fontWeight={'500'}>
              {title}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p={'0 24px'}>
              {body.map((item, index) => (
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  key={'confirm-modal-tr-' + index}
                  bg={bgOpacity}
                  mb={'10px'}
                  p={'8px'}
                >
                  <Box p={'8px'}>{item.value}</Box>
                  <Box p={'8px'}>{item.title}</Box>
                </Flex>
              ))}
              {dangerous ? (
                <Alert status={'error'} mt={'20px'}>
                  <AlertIcon />
                  Your position would be close to liquidation, if the collateral
                  loses value. Are you sure you want to proceed?
                </Alert>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button variant={'secondary'} mr={'10px'} onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant={'primary'}
                borderRadius={'0'}
                onClick={depositBorrow}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
