import {
  Box,
  Container,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';

export const MakeMostOf1UsdContext = React.createContext({} as any);

export default function MakeMostOf1UsdCtxProvider({
  children,
}: React.PropsWithChildren<any>) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  function MostOf1UsdPopover({ children }: { children: React.ReactNode }) {
    return (
      <>
        <Box>
          <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={onClose}
            placement={'bottom'}
            closeOnBlur={false}
            boundary={'scrollParent'}
            computePositionOnMount={true}
            matchWidth={true}
          >
            <PopoverTrigger>{children}</PopoverTrigger>
            <PopoverContent
              position={'absolute'}
              top={'50%'}
              right={'0'}
              transform={'translate(-50%, -50%)'}
              w={'100vw'}
              maxW={'max-content'}
              bg={'transparent'}
              border={'none'}
              borderRadius={'0'}
              d={'none'}
            >
              <Container variant={'token'} p={'10px 20px'}>
                <PopoverHeader fontWeight={'600'} p={'16px'}>
                  Make the MOST of your 1USD
                </PopoverHeader>
                <PopoverArrow bg={'transparent'} />
                <PopoverCloseButton />
                <PopoverBody p={'20px'}>
                  <UnorderedList>
                    <ListItem>
                      <Text>
                        Trade 1USD on{' '}
                        <a
                          href={'https://app.platypus.finance/swap'}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Platypus</Text>,{' '}
                        </a>
                        <a
                          href={
                            'https://traderjoexyz.com/trade?inputCurrency=AVAX&outputCurrency=0x0f577433bf59560ef2a79c124e9ff99fca258948'
                          }
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Trader Joe</Text>
                        </a>
                        , or{' '}
                        <a
                          href={'https://app.pangolin.exchange/#/swap'}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Pangolin.</Text>
                        </a>
                      </Text>
                    </ListItem>
                    <br />
                    <ListItem>
                      <Text>
                        Provide liquidity on{' '}
                        <a
                          href={
                            'https://app.platypus.finance/pool?pool_group=factory'
                          }
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Platypus</Text>,{' '}
                        </a>
                        <a
                          href={
                            'https://traderjoexyz.com/pool/0x0f577433bf59560ef2a79c124e9ff99fca258948/AVAX'
                          }
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Trader Joe</Text>
                        </a>
                        , or{' '}
                        <a
                          href={
                            'https://app.pangolin.exchange/#/add/AVAX/0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948'
                          }
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <Text as={'u'}>Pangolin.</Text>
                        </a>
                      </Text>
                    </ListItem>
                    <br />
                    <ListItem>
                      <Link to={'/i1usd'}>
                        <Text as={'u'}>Stake 1USD</Text>
                      </Link>{' '}
                      to earn interest using i1USD.
                    </ListItem>
                  </UnorderedList>
                </PopoverBody>
              </Container>
            </PopoverContent>
          </Popover>
        </Box>
      </>
    );
  }

  return (
    <MakeMostOf1UsdContext.Provider
      value={{ onToggle, isOpen, onClose, MostOf1UsdPopover }}
    >
      {children}
    </MakeMostOf1UsdContext.Provider>
  );
}
