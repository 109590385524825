import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import borrowDark from '../../../assets/borrow-dark.svg';
import borrowLight from '../../../assets/borrow-light.svg';
import deployDark from '../../../assets/deploy-dark.svg';
import deployLight from '../../../assets/deploy-light.svg';
import repayDark from '../../../assets/repay-dark.svg';
import repayLight from '../../../assets/repay-light.svg';
import { HowStep } from './HowStep';

export function HowSection() {
  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  return (
    <>
      <Box
        w={'90vw'}
        maxW={['190px', '225px', '265px', '300px', '340px']}
        m={'auto'}
        mt={['60px', '80px', '100px']}
      >
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['16px', '20px', '24px', '28px', '32px']}
            fontWeight={'700'}
            color={color}
            mb={['6px', '8px', '10px']}
          >
            HOW IT WORKS
          </Text>
          <Text
            fontSize={['10px', '12px', '14px', '16px', '18px']}
            fontWeight={'400'}
            color={colorDimmed}
          >
            Borrowing in Stout is easy. Just follow these simple steps.
          </Text>
        </Flex>
      </Box>
      <Box w={'90vw'} maxW={'1024px'} m={'auto'} mt={['40px', '50px', '40px']}>
        <Flex maxW={['480px', '480px', '800px']} m={'auto'}>
          <Grid
            templateColumns={['repeat(1, auto)']}
            templateRows={['repeat(2, auto)']}
            gap={'6'}
          >
            <HowStep
              step={'01'}
              title={'BORROW 1USD'}
              stepImgLight={borrowLight}
              stepImgDark={borrowDark}
            >
              <Text
                fontSize={['10px', '10px', '12px', '16px', '16px']}
                fontWeight={'400'}
                color={colorDimmed}
              >
                Unlock the full potential of your assets.{' '}
                <Link
                  as={ReactLink}
                  to={'/borrow'}
                  d={'inline-flex'}
                  textDecoration={'underline !important'}
                  color={colorDimmed}
                  fontWeight={'500'}
                  _hover={{
                    color: color,
                  }}
                >
                  Choose a collateral
                </Link>{' '}
                and make a deposit to borrow 1USD at a fixed interest rate.
              </Text>
            </HowStep>
            <HowStep
              step={'02'}
              title={'DEPLOY 1USD'}
              stepImgLight={deployLight}
              stepImgDark={deployDark}
            >
              <Text
                fontSize={['10px', '10px', '12px', '16px', '16px']}
                fontWeight={'400'}
                color={colorDimmed}
              >
                <strong>1 1USD = $1.</strong> Swap or spend it however you like.
                You can also pair it with USDT and use our{' '}
                <Link
                  d={'inline-flex'}
                  textDecoration={'underline !important'}
                  color={colorDimmed}
                  fontWeight={'500'}
                  cursor={'not-allowed'}
                  _hover={{
                    color: color,
                  }}
                >
                  Liquidity Vault
                </Link>{' '}
                to earn protocol fees.
              </Text>
            </HowStep>
            <HowStep
              step={'03'}
              title={'REPAY 1USD'}
              stepImgLight={repayLight}
              stepImgDark={repayDark}
            >
              <Text
                fontSize={['10px', '10px', '12px', '16px', '16px']}
                fontWeight={'400'}
                color={colorDimmed}
              >
                To close your position, at your convenience, go to the{' '}
                <Link
                  d={'inline-flex'}
                  textDecoration={'underline !important'}
                  color={colorDimmed}
                  fontWeight={'500'}
                  cursor={'not-allowed'}
                  _hover={{
                    color: color,
                  }}
                >
                  Dashboard
                </Link>{' '}
                and deposit 1USD to repay your debt and withdraw your
                collateral.
              </Text>
            </HowStep>
            <Flex
              flexDir={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              mt={['20px', '30px', '40px']}
            >
              <Text
                fontSize={['14px', '14px', '16px', '18px', '18px']}
                fontWeight={'500'}
              >
                Interested in learning more?
              </Text>
              <Link
                // href={'https://stout.gitbook.io/docs'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Button
                  variant={'secondary'}
                  fontSize={['12px', '12px', '14px', '16px', '16px']}
                  mt={['12px', '16px', '20px']}
                  cursor={'not-allowed'}
                >
                  READ OUR DOCS
                </Button>
              </Link>
            </Flex>
          </Grid>
        </Flex>
      </Box>
    </>
  );
}
