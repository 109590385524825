import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { NotFoundContent } from './components/NotFoundContent';

export default function Home(params: React.PropsWithChildren<unknown>) {
  // const account = React.useContext(UserAddressContext);
  // const location = useLocation();
  // const details = location.search?.includes('details=true');

  return (
    <>
      {params.children}
      <Outlet />
      <NotFoundContent />
    </>
  );
}
