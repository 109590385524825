import { ColorModeScript } from '@chakra-ui/react';
import {
  Avalanche,
  ChainId,
  DAppProvider,
  Hardhat,
  Localhost,
} from '@usedapp/core';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
// import { LiquidatablePositionsCtxProvider } from './contexts/LiquidatablePositionsContext';
// import AdminPage from './pages/Admin';
// import Analytics from './pages/Analytics';
import Dashboard from './pages/Dashboard';
import { ErrorBoundary } from './pages/ErrorBoundary/ErrorBoundary';
// import FarmPage from './pages/Farm';
import Home from './pages/Home';
// import I1UsdPage from './pages/i1Usd';
// import LiquidatablePositions from './pages/LiquidatablePositions';
// import LiquidationProtectedLoans from './pages/Loans';
import NotFound from './pages/NotFound';
import Terms from './pages/Terms';
// import PositionsPage from './pages/Positions';
// import StakePage from './pages/Stake';
// import VaultsPage from './pages/Vaults';
import TokenPage from './pages/TokenPage';
// import VeSttxTokenPage from './pages/vSttx';
// import XSttxPage from './pages/XSttx';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <DAppProvider
      config={{
        networks: [Avalanche, Hardhat, Localhost],
        readOnlyChainId: ChainId.Avalanche,

        readOnlyUrls: {
          [ChainId.Avalanche]: 'https://api.avax.network/ext/bc/C/rpc',
          // [ChainId.Localhost]: 'http://localhost:8545',
          // [ChainId.Hardhat]: 'http://localhost:8545',
        },
        // multicallAddresses: {
        // [ChainId.Localhost]: '0x0FB54156B496b5a040b51A71817aED9e2927912E',
        // [ChainId.Hardhat]: '0x0FB54156B496b5a040b51A71817aED9e2927912E',
        // },
        bufferGasLimitPercentage: 15,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<App />}>
            <Route index element={<Home />} />
            <Route path={'*'} element={<NotFound />} />
            <Route path={'/borrow'} element={<Dashboard />} />
            <Route path={'/terms'} element={<Terms />} />
            <Route
              // path={'/asset/:tokenAddress'}
              path={'/asset/:tokenTicker'}
              element={
                <ErrorBoundary>
                  <TokenPage />
                </ErrorBoundary>
              }
            />
            {/* <Route path={'/farm'} element={<FarmPage />} /> */}
            {/* <Route path={'/positions'} element={<PositionsPage />} /> */}
            {/* <Route
              path={'/liquidate'}
              element={
                <LiquidatablePositionsCtxProvider>
                  <LiquidatablePositions />
                </LiquidatablePositionsCtxProvider>
              }
            /> */}
            {/* <Route path={'/loans'} element={<LiquidationProtectedLoans />} /> */}
            {/* <Route path={'/stats'} element={<Analytics />} /> */}
            {/* <Route path={'/vaults'} element={<VaultsPage />} /> */}
            {/* <Route path={'/xsttx'} element={<XSttxPage />} /> */}
            {/* <Route path={'/i1usd'} element={<I1UsdPage />} /> */}
            {/* <Route path={'/vesttx'} element={<VeSttxTokenPage />} /> */}
            {/* <Route path={'/admin'} element={<AdminPage />} /> */}
            {/* <Route path={'/admin/:positions'} element={<AdminPage />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
