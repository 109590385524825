import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  useColorMode,
  useColorModeValue,
  // useColorModeValue,
  // Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import dark from '../../assets/dark.svg';
import light from '../../assets/light.svg';
import logoDark from '../../assets/logo-dark.svg';
import logoLight from '../../assets/logo-light.svg';
import fusion from '../../assets/crypto/FSN.svg';
import AccountModal from '../account/AccountModal';
import { UserAddressComponent } from '../account/UserAddressComponent';
import MenuOptions from './MenuOptions';
import { NavigationLink } from './NavigationLink';
import { NavigationLinkDisabled } from './NavigationLinkDisabled';

export default function NavigationBar() {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1920px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAccount,
    onOpen: onOpenAccount,
    onClose: onCloseAccount,
  } = useDisclosure();
  // const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const boxShadow = useColorModeValue(
    'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
    'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
  );

  const boxShadowLogo = useColorModeValue(
    '0 0 0 2px hsla(240, 2%, 12%, 0.2)',
    '0 0 0 2px hsla(200, 43%, 99%, 0.2)'
  );

  return (
    <>
      <Box position={'relative'} zIndex={'var(--chakra-zIndices-sticky)'}>
        <Flex
          alignItems={'center'}
          pt={'20px'}
          justifyContent={'space-between'}
        >
          <IconButton
            size={'lg'}
            bg={'transparent'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            d={'none'}
            onClick={isOpen ? onClose : onOpen}
          />
          <Flex
            position={{ base: 'fixed', xl: 'relative' }}
            w={'calc(100% / 3)'}
            alignItems={'center'}
            justifyContent={'start'}
          >
            <HStack
              as={'nav'}
              spacing={['20px', '30px', '30px']}
              d={'flex'}
              // d={'none'}
              position={{ base: 'fixed', xl: 'relative' }}
              bottom={'0'}
              left={{ base: '50%', xl: '0' }}
              transform={{ base: 'translate(-50%, -50%)', xl: 'none' }}
              w={{ base: 'auto', xl: 'max-content' }}
              alignItems={'center'}
              justifyContent={'center'}
              p={['10px 20px', '15px 30px', '15px 30px']}
              m={{ base: '0 auto', xl: '0' }}
              bg={bg}
              border={'none'}
              boxShadow={{
                base: boxShadow,
                xl: 'none',
              }}
              zIndex={'var(--chakra-zIndices-docked)'}
            >
              <NavigationLink page={'borrow'} />
              <NavigationLinkDisabled page={'mint'} />
              <NavigationLinkDisabled page={'vaults'} />
              {/* <NavigationLinkDisabled page={'farm'} /> */}
              <NavigationLinkDisabled page={'dashboard'} />
              {/* <NavigationLinkDisabled page={'positions'} /> */}
              <NavigationLinkDisabled page={'stats'} />
              {/* <NavigationLinkDisabled page={'liquidate'} /> */}
            </HStack>
          </Flex>
          <Flex
            w={'calc(100% / 3)'}
            alignItems={'center'}
            justifyContent={{ base: 'start', xl: 'center' }}
          >
            <Flex
              borderRadius={'full'}
              boxShadow={'none'}
              _hover={{
                boxShadow: boxShadowLogo,
              }}
            >
              <Link as={ReactLink} to={'/'} _focus={{ boxShadow: 'none' }}>
                <Flex
                  alignItems={'center'}
                  justifyContent={{ base: 'start', xl: 'center' }}
                  w={'36px'}
                >
                  {colorMode === 'light' ? (
                    <Image src={logoLight} alt={'Stout'} w={'full'} />
                  ) : (
                    <Image src={logoDark} alt={'Stout'} w={'full'} />
                  )}

                  {isLargerThan1280 ? '' : ''}
                </Flex>
              </Link>
            </Flex>
          </Flex>
          <Flex
            w={'calc(100% / 3)'}
            alignItems={'center'}
            justifyContent={'end'}
          >
            <HStack
              flexDir={'row'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Button
                variant={'link'}
                d={['none', 'flex', 'flex']}
                p={'8px'}
                h={'48px'}
                w={'48px'}
                borderRadius={'full'}
                pointerEvents={'none'}
              >
                <Image src={fusion} alt={'Fusion'} w={'full'} />
              </Button>
              <UserAddressComponent handleOpenModal={onOpenAccount} />
              <AccountModal isOpen={isOpenAccount} onClose={onCloseAccount} />
              <MenuOptions />
              <Button
                variant={'secondary'}
                p={'10px'}
                onClick={toggleColorMode}
              >
                {colorMode === 'light' ? (
                  <Image src={dark} alt={'Dark'} w={'full'} />
                ) : (
                  <Image src={light} alt={'Light'} w={'full'} />
                )}
              </Button>
            </HStack>
          </Flex>
        </Flex>
        {isOpen ? (
          <Box d={'none'} position={'absolute'} p={'10px'}>
            <Stack as={'nav'} spacing={'10px'}>
              <NavigationLink page={'borrow'} />
              <NavigationLinkDisabled page={'mint'} />
              <NavigationLinkDisabled page={'vaults'} />
              {/* <NavigationLinkDisabled page={'farm'} /> */}
              <NavigationLinkDisabled page={'dashboard'} />
              {/* <NavigationLinkDisabled page={'positions'} /> */}
              <NavigationLinkDisabled page={'stats'} />
              {/* <NavigationLinkDisabled page={'liquidate'} /> */}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
