import {
  Avatar,
  AvatarGroup,
  Flex,
  HStack,
  // Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Token } from '@usedapp/core';
import * as React from 'react';
import {
  // getAuxIconFromTokenAddress,
  getIconsFromTokenAddress,
} from '../../chain-interaction/tokens';
import { deprecatedTokenList } from '../../constants/deprecated-token-list';

export function TokenDescription({
  token,
  iconSize,
  textSize,
}: {
  token?: Token;
  iconSize?: string;
  textSize?: string;
}) {
  const targetSize = iconSize ?? 'sm';
  // const auxIcon = getAuxIconFromTokenAddress(token?.address ?? '');
  const isDeprecated = deprecatedTokenList.includes(token?.address ?? '');

  const border = useColorModeValue(
    '1.5px solid hsla(200, 43%, 99%, 0.8)',
    '1.5px solid hsla(240, 2%, 12%, 0.8)'
  );

  const boxShadow = useColorModeValue(
    '0 0 0 1.5px hsla(240, 2%, 12%, 0.2)',
    '0 0 0 1.5px hsla(200, 43%, 99%, 0.2)'
  );

  // const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  return token ? (
    <HStack spacing={'1'}>
      <Flex>
        <AvatarGroup size={targetSize} max={2}>
          {(getIconsFromTokenAddress(token.address) ?? []).map((iconUrl, i) => (
            <Avatar
              bg={'transparent'}
              // border={'none'}
              border={border}
              // boxShadow={'none'}
              boxShadow={boxShadow}
              showBorder={false}
              src={iconUrl}
              key={i + 1}
            />
          ))}
        </AvatarGroup>
        &nbsp;
        <Text size={textSize} isTruncated>
          {token.ticker}
          {isDeprecated ? <>(deprecated)</> : ''}
        </Text>
        {/* &nbsp; */}
        {/* {auxIcon ? (
          <>
            (
            <AvatarGroup size={targetSize} max={2}>
              <Avatar
                bg={'transparent'}
                border={'none'}
                // boxShadow={boxShadow}
                showBorder={false}
                src={auxIcon}
              />
            </AvatarGroup>
            )
          </>
        ) : undefined} */}
      </Flex>
    </HStack>
  ) : (
    <Text>Loading token information ...</Text>
  );
}
