import { Box, HStack } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';
import * as React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ParsedStratMetaRow } from '../../chain-interaction/contracts';
import { getTokenFromAddress } from '../../chain-interaction/tokens';
import { BackButton } from '../../components/navigation/BackButton';
import DeprecatedTokenMessage from '../../components/notifications/DeprecatedTokenMessage';
import { TokenDescription } from '../../components/tokens/TokenDescription';
import { StrategyMetadataContext } from '../../contexts/StrategyMetadataContext';
import { UserAddressContext } from '../../contexts/UserAddressContext';
import { PositionBody } from './components/PositionBody';
import { TokenPageBody } from './components/TokenPageBody';

export default function TokenPage(props: React.PropsWithChildren<unknown>) {
  const { chainId } = useEthers();
  const account = useContext(UserAddressContext);
  // const params = useParams<'tokenAddress'>();
  const params = useParams<'tokenTicker'>();
  // const tokenAddress = params.tokenAddress;
  const tokenTicker = params.tokenTicker;

  // console.log(tokenTicker);

  interface Addresses {
    [index: string]: string;
  }

  const addresses: Addresses = {
    BTC: '0x2148D1B21Faa7eb251789a51B404fc063cA6AAd6',
    // WBTC: '0x2148D1B21Faa7eb251789a51B404fc063cA6AAd6',
    'TF–USDT∕USDT': '0x454E67025631C065d3cFAD6d71E6892f74487a15',
    ETH: '0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7',
    FSN: '0x60781C2586D68229fde47564546784ab3fACA982',
    STTX: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    CHNG: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    'TF–USDC∕USDC': '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1',
    'TF–USDT': '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    'TF–DAI∕DAI': '0xCDFD91eEa657cc2701117fe9711C9a4F61FEED23',
    'TF–USDC': '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    // 'TF–ETH∕ETH': '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256',
    'TF–BUSD∕BUSD': '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256',
  };

  const tokenAddress = tokenTicker !== undefined ? addresses[tokenTicker] : '';

  const allStratMeta = React.useContext(StrategyMetadataContext);
  const token = tokenAddress
    ? getTokenFromAddress(chainId, tokenAddress)
    : undefined;

  const stratMeta: Record<string, ParsedStratMetaRow> =
    tokenAddress && tokenAddress in allStratMeta
      ? allStratMeta[tokenAddress]
      : {};

  return Object.values(stratMeta).length > 0 ? (
    <Box
      m={['20px auto', '30px auto', '40px auto']}
      overflowY={'hidden'}
      overflowX={'scroll'}
      w={'full'}
      maxW={'max-content'}
      p={'0 10px'}
      // m={'0 -5px'}
    >
      <Box
        w={['full', '300px', '300px']}
        filter={'blur(200px)'}
        opacity={'0.3'}
        right={'300px'}
        pointerEvents={'none'}
        zIndex={'var(--chakra-zIndices-base)'}
      />
      <DeprecatedTokenMessage />
      <HStack spacing={'20px'}>
        <BackButton />
        {token ? (
          <TokenDescription token={token} iconSize={'sm'} textSize={'6xl'} />
        ) : undefined}
      </HStack>
      {account ? (
        <TokenPageBody
          tokenAddress={tokenAddress}
          stratMeta={stratMeta}
          account={account}
        />
      ) : (
        <PositionBody stratMeta={stratMeta} />
      )}
      {props.children}
    </Box>
  ) : (
    <> </>
  );
}
