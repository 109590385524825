import {
  Flex,
  Image,
  Link,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export function SocialLink({
  link,
  title,
  imgLight,
  imgDark,
}: React.PropsWithChildren<{
  link: string;
  title: string;
  imgLight: any;
  imgDark: any;
}>) {
  const { colorMode } = useColorMode();

  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  // const colorDimmed = useColorModeValue(
  //   'hsla(240, 2%, 12%, 0.65)',
  //   'hsla(200, 43%, 99%, 0.65)'
  // );

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  const boxShadow = useColorModeValue(
    'inset 0 0 0 2px hsla(240, 2%, 12%, 0.2)',
    'inset 0 0 0 2px hsla(200, 43%, 99%, 0.2)'
  );

  return (
    <>
      <Link href={link} target={'_blank'} rel={'noreferrer'}>
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          bg={gradientBg}
          py={['20px', '40px', '60px']}
          w={'full'}
          color={color}
          boxShadow={boxShadow}
          opacity={'0.65'}
          fontWeight={'400'}
          _hover={{ opacity: '1', fontWeight: '600' }}
        >
          {colorMode === 'light' ? (
            <Image
              h={['20px', '30px', '40px']}
              w={['40px', '50px', '60px']}
              src={imgLight}
              alt={title}
            />
          ) : (
            <Image
              h={['20px', '30px', '40px']}
              w={['40px', '50px', '60px']}
              src={imgDark}
              alt={title}
            />
          )}
          <Text
            fontSize={['11px', '13px', '15px']}
            mt={['8px', '16px', '20px']}
            textTransform={'uppercase'}
          >
            {title}
          </Text>
        </Flex>
      </Link>
    </>
  );
}
