import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CurrencyValue } from '@usedapp/core';
import * as React from 'react';

export function TokenAmountInputField(props: {
  name: string;
  max?: CurrencyValue;
  placeholder: string;
  registerForm: (name: string, params: { required: string }) => any;
  setValueForm: (
    name: string,
    max: string,
    args?: { shouldDirty: boolean; shouldTouch: boolean }
  ) => any;
  errorsForm?: Record<string, any>;
  isDisabled?: boolean;
  percentage?: string;
  width?: string;
}) {
  const {
    name,
    max,
    placeholder,
    registerForm,
    setValueForm,
    errorsForm,
    isDisabled,
    percentage,
  } = props;

  const error = errorsForm?.[name];

  // const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65) !important',
    'hsla(200, 43%, 99%, 0.65) !important'
  );

  const bgOpacity = useColorModeValue(
    'hsla(240, 2%, 12%, 0.08) !important',
    'hsla(200, 43%, 99%, 0.08) !important'
  );

  const regexDec = /^\d*\.?$/,
    decimalChange = (event: any) => {
      if (event.key.length > 1 || regexDec.test(event.key)) return;
      event.preventDefault();
    };

  // const minInput = 0;
  // const maxInput = 1000000000000;

  // const [value, setValue] = React.useState(0);

  // const handleChange = (event: { target: { value: any } }) => {
  //   const value = Math.max(
  //     minInput,
  //     Math.min(maxInput, Number(event.target.value))
  //   );
  //   setValue(value);
  // };

  return (
    <Flex pl={'8px'}>
      <FormControl
        isInvalid={error}
        isDisabled={isDisabled}
        borderRadius={'0'}
        w={'full'}
        // maxW={'400px'}
        bg={bgOpacity}
      >
        <InputGroup>
          <Input
            {...registerForm(name, {
              required: '',
            })}
            placeholder={placeholder}
            type={'number'}
            inputMode={'numeric'}
            step={'any'}
            autoComplete={'off'}
            autoCorrect={'off'}
            defaultValue={''}
            // value={value}
            minLength={'1'}
            maxLength={'10'}
            spellCheck={'false'}
            pattern={'^[0-9][.,]?[0-9]$'}
            border={'none'}
            pl={'10px'}
            pr={'210px'}
            h={'44px'}
            borderRadius={'0'}
            onKeyPress={decimalChange}
            // onChange={handleChange}
          />
          <InputRightElement h={'full'} w={'auto'} pr={'10px'}>
            {max ? (
              <Button
                variant={'primary'}
                size={'xs'}
                isDisabled={isDisabled}
                onClick={() =>
                  setValueForm(
                    name,
                    max.format({
                      significantDigits: Infinity,
                      prefix: '',
                      suffix: '',
                      thousandSeparator: '',
                      decimalSeparator: '.',
                    }),
                    { shouldDirty: true, shouldTouch: true }
                  )
                }
              >
                <Text variant={'bodyExtraSmall'} fontWeight={'500'}>
                  MAX
                </Text>
              </Button>
            ) : percentage ? (
              <Button
                variant={'secondary'}
                size={'xs'}
                // isDisabled={true}
                pointerEvents={'none'}
              >
                <Text
                  variant={'bodyExtraSmall'}
                  fontWeight={'500'}
                  color={colorDimmed}
                >
                  {percentage}
                </Text>
              </Button>
            ) : (
              <></>
            )}
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    </Flex>
  );
}
