import { Image, Tab, Text } from '@chakra-ui/react';
import * as React from 'react';

export function FeaturedAssetsNetwork({
  name,
  img,
  opacity,
  pointerEvents,
}: React.PropsWithChildren<{
  name: string;
  img: any;
  opacity?: string;
  pointerEvents?: any;
}>) {
  return (
    <>
      <Tab
        fontSize={['11px', '12px', '13px']}
        w={'max-content'}
        bg={'transparent !important'}
        minW={'max-content'}
        opacity={opacity}
        pointerEvents={pointerEvents}
      >
        <Image h={['21px', '24.5px', '28px']} src={img} alt={name} />
        <Text
          fontSize={['12px', '13px', '14px']}
          textTransform={'uppercase'}
          ml={'12px'}
        >
          {name}
        </Text>
      </Tab>
    </>
  );
}
