import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';
import * as React from 'react';
import { useContext } from 'react';
import { UserAddressContext } from '../../contexts/UserAddressContext';

export default function PhishingAlertComponent() {
  const { account } = useEthers();
  const userAccount = useContext(UserAddressContext);

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  return (
    <>
      {account != userAccount ? (
        <Box bg={'red'} w={'full'} p={'3'} color={color} textAlign={'center'}>
          <Text size={'4xl'} as={'mark'}>
            <strong>
              Phishing danger: do not make any transactions unless you know what
              you&apos;re doing!
            </strong>
          </Text>
        </Box>
      ) : (
        ''
      )}
    </>
  );
}
