import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  CurrencyValue,
  useEtherBalance,
  useEthers,
  useTokenAllowance,
} from '@usedapp/core';
import axios from 'axios';
import { BigNumber } from 'ethers';
import { getAddress } from 'ethers/lib/utils';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  calcLiqPriceFromNum,
  ParsedPositionMetaRow,
  ParsedStratMetaRow,
  TxStatus,
  useStable,
} from '../../../../chain-interaction/contracts';
import {
  useApproveTrans,
  useDepositBorrowTrans,
  useNativeDepositBorrowTrans,
} from '../../../../chain-interaction/transactions';
import { EnsureWalletConnected } from '../../../../components/account/EnsureWalletConnected';
import { TransactionErrorDialog } from '../../../../components/notifications/TransactionErrorDialog';
import WarningMessage from '../../../../components/notifications/WarningMessage';
import { TokenAmountInputField } from '../../../../components/tokens/TokenAmountInputField';
import { TokenDescription } from '../../../../components/tokens/TokenDescription';
import { WNATIVE_ADDRESS } from '../../../../constants/addresses';
import { MakeMostOf1UsdContext } from '../../../../contexts/MakeMostOf1UsdContext';
import { UserAddressContext } from '../../../../contexts/UserAddressContext';
import { useWalletBalance } from '../../../../contexts/WalletBalancesContext';
import { parseFloatCurrencyValue, parseFloatNoNaN } from '../../../../utils';
import { ConfirmPositionModal } from './ConfirmPositionModal';

export default function DepositBorrow({
  position,
  stratMeta,
}: React.PropsWithChildren<{
  position?: ParsedPositionMetaRow;
  stratMeta: ParsedStratMetaRow;
}>) {
  const { token, strategyAddress, borrowablePercent, usdPrice } = stratMeta;
  const { chainId } = useEthers();
  const [data, setData] = useState<{ [x: string]: any }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onToggle, onClose: onClosePopover } = React.useContext(
    MakeMostOf1UsdContext
  );
  const account = useContext(UserAddressContext);
  const stable = useStable();

  const isNativeToken = WNATIVE_ADDRESS[chainId!] === token.address;

  const allowTokenExtrawurst =
    getAddress(token.address) === '0x9e295B5B976a184B14aD8cd72413aD846C299660'
      ? '0x5643F4b25E36478eE1E90418d5343cb6591BcB9d'
      : token.address;
  const allowResult = useTokenAllowance(
    allowTokenExtrawurst,
    account,
    strategyAddress
  );
  const allowCV = new CurrencyValue(token, allowResult ?? BigNumber.from('0'));
  const allowance = token.address && account && strategyAddress && allowCV;

  const etherBalance = useEtherBalance(account);

  const nativeTokenBalance = etherBalance
    ? new CurrencyValue(token, etherBalance)
    : new CurrencyValue(token, BigNumber.from('0'));

  const walletBalance =
    useWalletBalance(token.address) ??
    new CurrencyValue(token, BigNumber.from('0'));

  const { approveState, sendApprove } = useApproveTrans(token.address);

  const {
    handleSubmit: handleSubmitDepForm,
    register: registerDepForm,
    setValue: setValueDepForm,
    formState: { errors: errorsDepForm, isSubmitting: isSubmittingDepForm },
    watch,
  } = useForm();

  const { sendDepositBorrow, depositBorrowState } = useDepositBorrowTrans(
    position ? position.trancheId : undefined
    // position ? position.trancheContract : undefined
  );
  const {
    sendDepositBorrow: sendNativeDepositBorrow,
    depositBorrowState: nativeDepositBorrowState,
  } = useNativeDepositBorrowTrans(
    position ? position.trancheId : undefined
    // position ? position.trancheContract : undefined
  );

  function onDepositBorrow(data: { [x: string]: any }) {
    // console.log('deposit borrow');
    // console.log(data);
    setData(data);
    onOpen();
  }

  function confirmDeposit() {
    if (isNativeToken) {
      sendNativeDepositBorrow(
        token,
        strategyAddress,
        data!['collateral-deposit'] || '0',
        data!['1usd-borrow'] || '0'
      );
    } else {
      sendDepositBorrow(
        token,
        strategyAddress,
        data!['collateral-deposit'] || '0',
        data!['1usd-borrow'] || '0'
      );
    }
  }

  const [collateralInput, borrowInput, customPercentageInput] = watch([
    'collateral-deposit',
    '1usd-borrow',
    'custom-percentage',
  ]);

  const inputExceedsAllowance =
    allowCV &&
    parseFloatNoNaN(collateralInput) > parseFloatCurrencyValue(allowCV);

  const extantCollateral =
    position && position.collateral
      ? parseFloatCurrencyValue(position.collateral)
      : 0;
  const totalCollateral = parseFloatNoNaN(collateralInput) + extantCollateral;

  const extantDebt =
    position && position.debt && position.debt.gt(position.yield)
      ? parseFloatCurrencyValue(position.debt.sub(position.yield))
      : 0;
  const totalDebt = parseFloatNoNaN(borrowInput) + extantDebt;

  const currentPercentage =
    totalCollateral > 0 && usdPrice > 0
      ? (100 * extantDebt) / (totalCollateral * usdPrice)
      : 0;
  const percentageRange = borrowablePercent - currentPercentage;

  const percentageStep = Math.max(percentageRange / 5, 10);
  const percentageSteps =
    10 >= percentageRange
      ? [(currentPercentage + borrowablePercent) / 2]
      : Array(Math.floor((percentageRange - 0.5) / percentageStep))
          .fill(currentPercentage)
          .map((p, i) => Math.round((p + (i + 1) * percentageStep) / 5) * 5);

  const totalPercentage =
    totalCollateral > 0 && usdPrice > 0
      ? (100 * totalDebt) / (totalCollateral * usdPrice)
      : 0;

  const percentageLabel =
    totalCollateral > 0 && usdPrice > 0
      ? `${totalPercentage.toFixed(0)} %`
      : 'LTV %';
  const percentages = Object.fromEntries(
    percentageSteps.map((percentage) => [
      `${percentage.toFixed(0)} %`,
      (percentage * totalCollateral * usdPrice) / 100 - extantDebt,
    ])
  );

  const showWarning =
    !(
      parseFloatNoNaN(collateralInput) === 0 &&
      parseFloatNoNaN(borrowInput) === 0
    ) && totalPercentage > borrowablePercent;

  React.useEffect(() => {
    // console.log('In effect', customPercentageInput);
    if (customPercentageInput) {
      setValueDepForm(
        '1usd-borrow',
        (customPercentageInput * totalCollateral * usdPrice) / 100 - extantDebt,
        { shouldDirty: true }
      );
    }
  }, [customPercentageInput, totalCollateral, extantDebt, usdPrice]);

  React.useEffect(() => {
    async function waitTransactionResult() {
      const depositBorrowResult = await depositBorrowState.transaction?.wait();
      const nativeDepositBorrowResult =
        await nativeDepositBorrowState.transaction?.wait();
      if (
        depositBorrowResult?.status === 1 ||
        nativeDepositBorrowResult?.status === 1
      ) {
        onToggle();
        setTimeout(() => {
          onClosePopover();
        }, 60000);
      }
    }
    waitTransactionResult();
  }, [depositBorrowState, nativeDepositBorrowState]);

  const depositBorrowDisabled =
    !position &&
    (isNativeToken ? nativeTokenBalance.isZero() : walletBalance.isZero());

  // const isJoeToken =
  //   getAddress(token.address) ===
  //   getAddress('0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd');

  // const depositBorrowButtonDisabledForJoe =
  //   parseFloatNoNaN(collateralInput) > 0 && isJoeToken;

  const depositBorrowButtonDisabled =
    (parseFloatNoNaN(collateralInput) === 0 &&
      parseFloatNoNaN(borrowInput) === 0) ||
    totalPercentage > borrowablePercent;

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const bgOpacity = useColorModeValue(
    'hsla(240, 2%, 12%, 0.08)',
    'hsla(200, 43%, 99%, 0.08)'
  );

  const inputBg = useColorModeValue(
    'hsla(240, 2%, 12%, 0.04)',
    'hsla(200, 43%, 99%, 0.04)'
  );

  const inputStyle = {
    p: '10px 10px 10px 18px',
    bg: inputBg,
    borderRadius: '0',
    justifyContent: 'space-between',
  };

  const liquidatableZone = borrowablePercent;
  const criticalZone = (90 * borrowablePercent) / 100;
  const riskyZone = (80 * borrowablePercent) / 100;
  const healthyZone = (50 * borrowablePercent) / 100;

  const positionHealthColor =
    0.1 > totalDebt
      ? 'blue'
      : totalPercentage > liquidatableZone
      ? 'purple'
      : totalPercentage > criticalZone
      ? 'red'
      : totalPercentage > riskyZone
      ? 'orange'
      : totalPercentage > healthyZone
      ? 'green'
      : 'blue';
  const positionHealth = {
    blue: 'Safe',
    green: 'Healthy',
    orange: 'Risky',
    red: 'Critical',
    purple: 'Liquidatable',
  };

  // console.log(
  //   'DepositBorrow',
  //   position?.debt,
  //   borrowablePercent,
  //   totalPercentage,
  //   currentPercentage
  // );

  const dangerousPosition = totalPercentage > borrowablePercent * 0.92;
  // console.log('customPercentageInput', customPercentageInput);

  const balance = isNativeToken ? nativeTokenBalance : walletBalance;

  const [priceBTC, setPriceBTC] = React.useState<number>(0);
  const [priceETH, setPriceETH] = React.useState<number>(0);
  const [priceFSN, setPriceFSN] = React.useState<number>(0);
  const [priceCHNG, setPriceCHNG] = React.useState<number>(0);

  const GetCoinPrices = () => {
    const getPriceBTC = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=BTC'
        );
        const data = response.data;
        setPriceBTC(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceBTC();
      const interval = setInterval(() => {
        getPriceBTC();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceETH = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=ETH'
        );
        const data = response.data;
        setPriceETH(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceETH();
      const interval = setInterval(() => {
        getPriceETH();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceFSN = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=FSN'
        );
        const data = response.data;
        setPriceFSN(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceFSN();
      const interval = setInterval(() => {
        getPriceFSN();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceCHNG = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=CHNG'
        );
        const data = response.data;
        setPriceCHNG(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceCHNG();
      const interval = setInterval(() => {
        getPriceCHNG();
      }, 10000);

      return () => clearInterval(interval);
    }, []);
  };

  GetCoinPrices();

  function numberWithCommas(n: string) {
    return n.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  let assetPrice;

  switch (token.ticker) {
    // case 'WBTC':
    case 'BTC':
      assetPrice = numberWithCommas(
        (Math.floor(priceBTC * 100) / 100).toFixed(2)
      );
      break;
    case 'ETH':
      assetPrice = numberWithCommas(
        (Math.floor(priceETH * 100) / 100).toFixed(2)
      );
      break;
    case 'FSN':
      assetPrice = numberWithCommas(
        (Math.floor(priceFSN * 10000) / 10000).toFixed(4)
      );
      break;
    case 'CHNG':
      assetPrice = numberWithCommas(
        (Math.floor(priceCHNG * 10000) / 10000).toFixed(4)
      );
      break;
    default:
      '...';
  }

  assetPrice === undefined ? (assetPrice = '...') : assetPrice;

  return (
    <>
      <ConfirmPositionModal
        title={'Confirm Deposit / Borrow'}
        isOpen={isOpen}
        onClose={onClose}
        confirm={confirmDeposit}
        body={[
          {
            title: <TokenDescription token={stratMeta.token} />,
            value: (
              <Text>
                {data
                  ? parseFloatNoNaN(data!['collateral-deposit'].toString())
                  : ''}
              </Text>
            ),
          },
          {
            title: <TokenDescription token={stable} />,
            value: (
              <Text>
                {data ? parseFloatNoNaN(data!['1usd-borrow'].toString()) : ''}
              </Text>
            ),
          },
          {
            // title: 'At Loan-To-Value [%]',
            value: 'LTV Ratio',
            title: totalPercentage.toFixed(2) + ' %',
          },
        ]}
        dangerous={dangerousPosition}
      />
      <form onSubmit={handleSubmitDepForm(onDepositBorrow)}>
        <Flex flexDir={'column'} justify={'start'}>
          <Flex
            w={'full'}
            mb={'6px'}
            flexDir={'row'}
            justifyContent={'space-between'}
          >
            <WarningMessage
              message={'Deposits currently disabled.'}
              // isOpen={depositBorrowButtonDisabledForJoe}
              isOpen={false}
            >
              <Text variant={'bodyExtraSmall'} color={colorDimmed}>
                Deposit Collateral
              </Text>
            </WarningMessage>
            <Text variant={'bodyExtraSmall'} color={colorDimmed}>
              Balance: {balance.format({ suffix: '' })}
            </Text>
          </Flex>
          <HStack {...inputStyle}>
            <TokenDescription token={stratMeta.token} />
            <TokenAmountInputField
              name={'collateral-deposit'}
              max={balance}
              isDisabled={depositBorrowDisabled}
              placeholder={'0'}
              registerForm={registerDepForm}
              setValueForm={setValueDepForm}
              errorsForm={errorsDepForm}
            />
          </HStack>
        </Flex>
        <Flex flexDir={'column'} justify={'start'} mt={'20px'}>
          <Box w={'full'} textAlign={'start'} mb={'6px'}>
            <WarningMessage
              message={'BORROW AMOUNT TOO HIGH'}
              isOpen={showWarning}
            >
              <Text variant={'bodyExtraSmall'} color={colorDimmed}>
                Borrow 1USD
              </Text>
            </WarningMessage>
          </Box>
          <HStack {...inputStyle}>
            <TokenDescription token={stable} />
            <TokenAmountInputField
              name={'1usd-borrow'}
              isDisabled={depositBorrowDisabled}
              placeholder={'0'}
              registerForm={registerDepForm}
              setValueForm={setValueDepForm}
              errorsForm={errorsDepForm}
              percentage={percentageLabel}
            />
          </HStack>
        </Flex>
        <br />
        <HStack
          justifyContent={'space-between'}
          // maxW={'max-content'}
          ml={'auto'}
          mr={'0'}
        >
          {percentages &&
            Object.entries(percentages).map(([key, value]) => (
              <Button
                variant={'secondary'}
                borderRadius={'0'}
                p={'0 24px'}
                w={'full'}
                key={'percentage' + key}
                onClick={() => {
                  setValueDepForm('custom-percentage', '');
                  setValueDepForm('1usd-borrow', value.toFixed(10), {
                    shouldDirty: true,
                  });
                }}
              >
                <Text variant={'bodySmall'} fontWeight={'500'}>
                  {key}
                </Text>
              </Button>
            ))}
          <InputGroup minW={'104px'} maxW={'104px'} border={'none'}>
            <Input
              {...registerDepForm('custom-percentage')}
              key={'custom'}
              autoComplete={'off'}
              placeholder={'Custom'}
              variant={'percentage'}
              fontSize={'14px'}
            />
            <InputRightElement w={'auto'} mr={'16px'}>
              %
            </InputRightElement>
          </InputGroup>
        </HStack>
        <br />
        <HStack justifyContent={'space-between'} mt={'10px'}>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              Deposit Value
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              $ {(usdPrice * (totalCollateral - extantCollateral)).toFixed(2)}
            </Text>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              Liquidation Price
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              ${' '}
              {calcLiqPriceFromNum(
                borrowablePercent,
                totalDebt,
                totalCollateral
              ).toFixed(2)}
            </Text>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              {positionHealth[positionHealthColor]} Position
            </Text>
            <Box h={'24px'} m={'2px'} d={'flex'} alignItems={'center'}>
              <Progress
                colorScheme={positionHealthColor}
                value={(100 * totalPercentage) / borrowablePercent}
                w={'100px'}
                h={'14px'}
                borderRadius={'0'}
                opacity={'0.65'}
                bg={bgOpacity}
              />
            </Box>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              cRatio
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              {totalDebt > 0.01
                ? `${((100 * usdPrice * totalCollateral) / totalDebt).toFixed(
                    2
                  )} %`
                : '∞'}
            </Text>
          </VStack>
        </HStack>
        <HStack mt={'30px'} spacing={'8px'} justifyContent={'center'}>
          {/* <Text variant={'h300'} color={colorDimmed}>
            Price:
          </Text> */}
          {/* <Text variant={'bodySmall'}>{`1 ${
            token.ticker
          } = $ ${usdPrice.toFixed(2)}`}</Text> */}
          <Text
            variant={'bodySmall'}
          >{`1 ${token.ticker} = $ ${assetPrice}`}</Text>
        </HStack>
        <TransactionErrorDialog state={approveState} title={'Approve'} />
        <TransactionErrorDialog
          state={depositBorrowState}
          title={'Deposit & Borrow'}
        />
        <TransactionErrorDialog
          state={nativeDepositBorrowState}
          title={'Deposit & Borrow'}
        />

        <Box mt={'10px'}>
          {allowance && inputExceedsAllowance && !isNativeToken ? (
            <EnsureWalletConnected>
              <Button
                variant={'primary'}
                h={'56px'}
                w={'full'}
                onClick={() => sendApprove(strategyAddress)}
                isLoading={
                  approveState.status === TxStatus.MINING &&
                  allowance &&
                  inputExceedsAllowance
                }
              >
                Approve {token.name}{' '}
              </Button>
            </EnsureWalletConnected>
          ) : (
            <Button
              type={'submit'}
              variant={depositBorrowButtonDisabled ? 'disabled' : 'primary'}
              h={'56px'}
              w={'full'}
              // pointerEvents={'none'}
              // cursor={'not-allowed'}
              isLoading={isSubmittingDepForm}
              isDisabled={
                // depositBorrowButtonDisabled || depositBorrowButtonDisabledForJoe
                // depositBorrowButtonDisabled
                true
              }
            >
              DEPOSIT & BORROW
            </Button>
          )}
        </Box>
      </form>
    </>
  );
}
