import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  Text,
  useColorMode,
  useColorModeValue,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import enterDarkImg from '../../../assets/enter-dark.svg';
import enterLightImg from '../../../assets/enter-light.svg';

export function HomeButtons({
  borrowButtonDisplay,
  mintButtonDisplay,
  arrowsDisplay,
  mTop,
}: {
  borrowButtonDisplay: string;
  mintButtonDisplay: string;
  arrowsDisplay: string;
  mTop?: any;
}) {
  const { colorMode } = useColorMode();

  const border = useColorModeValue(
    '2px solid hsla(240, 2%, 12%, 0.8)',
    '2px solid hsla(200, 43%, 99%, 0.8)'
  );

  const pulse = keyframes`
    from { opacity: 0; }
    to { opacity: 0.65; }
  `;

  const prefersReducedMotion = usePrefersReducedMotion();

  const pulseAnimationOdd = prefersReducedMotion
    ? undefined
    : `${pulse} 500ms ease infinite alternate`;

  const pulseAnimationEven = prefersReducedMotion
    ? undefined
    : `${pulse} 500ms ease infinite alternate 250ms`;

  return (
    <>
      <Box
        w={'90vw'}
        maxW={'640px'}
        m={['30px auto', '40px auto', '50px auto']}
        mt={mTop}
      >
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          textAlign={'center'}
          w={'max-content'}
          m={'auto'}
        >
          <Flex d={borrowButtonDisplay}>
            <Link to={'/borrow'}>
              <Button
                variant={'secondary'}
                fontSize={['12px', '12px', '14px', '16px', '16px']}
                mx={['4px', '8px', '16px']}
                pr={'0'}
              >
                <Text
                  textAlign={'left'}
                  w={['45px', '45px', '55px', '60px', '60px']}
                >
                  BORROW
                </Text>
                {colorMode === 'light' ? (
                  <Image
                    h={'60px'}
                    ml={['18px', '48px', '64px', '64px', '64px']}
                    mr={'-6px'}
                    mb={'10px'}
                    src={enterLightImg}
                  />
                ) : (
                  <Image
                    h={'60px'}
                    ml={['18px', '48px', '64px', '64px', '64px']}
                    mr={'-6px'}
                    mb={'10px'}
                    src={enterDarkImg}
                  />
                )}
              </Button>
            </Link>
          </Flex>
          <Flex d={mintButtonDisplay}>
            {/* <Link to={'/mint'}> */}
            <Button
              variant={'primary'}
              fontSize={['12px', '12px', '14px', '16px', '16px']}
              mx={['4px', '8px', '16px']}
              pr={'0'}
              cursor={'not-allowed'}
            >
              <Text
                textAlign={'left'}
                w={['45px', '45px', '55px', '60px', '60px']}
              >
                MINT
              </Text>
              {colorMode === 'light' ? (
                <Image
                  h={'60px'}
                  ml={['18px', '48px', '64px', '64px', '64px']}
                  mr={'-6px'}
                  mb={'10px'}
                  src={enterDarkImg}
                />
              ) : (
                <Image
                  h={'60px'}
                  ml={['18px', '48px', '64px', '64px', '64px']}
                  mr={'-6px'}
                  mb={'10px'}
                  src={enterLightImg}
                />
              )}
            </Button>
            {/* </Link> */}
          </Flex>
        </Flex>
        <Box
          w={'max-content'}
          justifyContent={'center'}
          m={'auto'}
          py={['30px', '40px', '50px']}
          d={arrowsDisplay}
        >
          <Flex flexDir={'column'}>
            <Flex
              animation={pulseAnimationOdd}
              d={'inline-block'}
              h={'10px'}
              w={'10px'}
              border={border}
              borderWidth={'0 3px 3px 0'}
              transform={'rotate(45deg)'}
            ></Flex>
            <Flex
              animation={pulseAnimationEven}
              d={'inline-block'}
              h={'10px'}
              w={'10px'}
              border={border}
              borderWidth={'0 3px 3px 0'}
              transform={'rotate(45deg)'}
            ></Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
