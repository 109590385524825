import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export function TableSearch({
  setSearchString,
}: {
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}) {
  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const stripped = event.target.value.trim();
    setSearchString(stripped.toLowerCase());
  };

  const color = useColorModeValue('#1D1D1E !important', '#FAFCFD !important');

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2) !important',
    '1px solid hsla(200, 43%, 99%, 0.2) !important'
  );

  return (
    <Box w={['full', 'full', 'auto']} mt={['12px', '12px', '0']}>
      <InputGroup>
        <InputLeftElement pointerEvents={'none'}>
          <SearchIcon />
        </InputLeftElement>
        <Input
          type={'text'}
          autoComplete={'off'}
          placeholder={'Search'}
          bg={'transparent'}
          color={color}
          borderRadius={'0'}
          border={border}
          fontSize={'14px'}
          onChange={handleSearch}
        />
      </InputGroup>
    </Box>
  );
}
