import React from 'react';
import { VStack, Text, useColorModeValue } from '@chakra-ui/react';

type Props = {
  title: string;
  value: any;
};

export function TitleValue({ title, value }: Props) {
  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  return (
    <VStack spacing={['10px', '12px', '16px']} align={'center'} m={['16px']}>
      <Text variant={'h400'} color={colorDimmed}>
        {title}
      </Text>
      <Text variant={'bodySmall'}>{value}</Text>
    </VStack>
  );
}
