import { Box, ChakraProvider } from '@chakra-ui/react';
// import { useConfig, useEthers } from '@usedapp/core';
import { useEthers } from '@usedapp/core';
import { ethers } from 'ethers';
import * as React from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAddresses } from './chain-interaction/contracts';
import NavigationBar from './components/navigation/NavigationBar';
import GlobalDebtCeilingMessage from './components/notifications/GlobalDebtCeilingMessage';
// import MigratePositionsComponent from './components/notifications/MigratePositionsComponent';
import NetworkNotSupported from './components/notifications/NetworkNotSupported';
import PhishingAlertComponent from './components/notifications/PhishingAlertComponent';
import { TransactionToasts } from './components/notifications/TransactionToasts';
import { ExternalMetadataCtxProvider } from './contexts/ExternalMetadataContext';
import { LiquidationFeesCtxProvider } from './contexts/LiquidationFeesContext';
import MakeMostOf1UsdCtxProvider from './contexts/MakeMostOf1UsdContext';
import { StrategyMetadataCtxProvider } from './contexts/StrategyMetadataContext';
import { UserAddressCtxProvider } from './contexts/UserAddressContext';
import { WalletBalancesCtxProvider } from './contexts/WalletBalancesContext';
import { theme } from './theme';

declare let window: any;

export const App = (params: React.PropsWithChildren<unknown>) => {
  const addresses = useAddresses();
  const { active, chainId, activateBrowserWallet, account } = useEthers();

  // const config = useConfig();
  // const chainIdsList = config.networks!.map((network) => network.chainId);
  // const chainIdsList = [31337, 43114, 32659];
  const chainIdsList = [32659];

  const [requestedSwitch, setRequestedSwitch] = React.useState(false);
  const [tabHasFocus, setTabHasFocus] = React.useState(true);

  useEffect(() => {
    (async () => {
      try {
        const wallet = new ethers.providers.Web3Provider(window.ethereum);
        const { chainId: walletChainId } = await wallet.getNetwork();
        const accounts = await wallet.listAccounts();

        // console.log(chainId);
        // console.log(walletChainId);

        // if (chainId === 31337) {
        //   activateBrowserWallet();
        // }

        if (
          accounts.length > 0 &&
          !requestedSwitch &&
          walletChainId &&
          !chainIdsList.includes(walletChainId) &&
          tabHasFocus
        ) {
          setRequestedSwitch(true);
          try {
            await wallet.provider.request!({
              method: 'wallet_switchEthereumChain',
              // params: [{ chainId: '0xa86a' }],
              params: [{ chainId: '0x7f93' }],
            });
            activateBrowserWallet();
          } catch (switchError) {
            try {
              await wallet.provider.request!({
                method: 'wallet_addEthereumChain',
                // params: [
                //   {
                //     chainId: '0xa86a',
                //     chainName: 'Avalanche Network',
                //     nativeCurrency: {
                //       name: 'avax',
                //       symbol: 'AVAX',
                //       decimals: 18,
                //     },
                //     rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                //     blockExplorerUrls: ['https://snowtrace.io/'],
                //   },
                // ],
                params: [
                  {
                    chainId: '0x7f93',
                    chainName: 'Fusion Mainnet',
                    nativeCurrency: {
                      name: 'Fusion',
                      symbol: 'FSN',
                      decimals: 18,
                    },
                    rpcUrls: ['https://mainnet.fusionnetwork.io'],
                    blockExplorerUrls: ['https://fsnscan.com/'],
                  },
                ],
              });
              activateBrowserWallet();
            } catch (addError) {
              // activateBrowserWallet();
              // alert(`Your wallet may be connected to an unsupported network.`);
              console.log(
                'Your wallet may be connected to an unsupported network.'
              );
            }
          }
        } else if (accounts.length > 0 && !account) {
          activateBrowserWallet();
        }
      } catch (err) {
        console.log(
          'You need to install a Web3 wallet like MetaMask, Rabby or Frame to interact with the protocol.'
        );
      }
    })();
  }, [active, chainId, tabHasFocus]);

  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location]);

  useEffect(() => {
    const handleFocus = () => {
      if (document.visibilityState == 'visible') {
        setTabHasFocus(true);
        // console.log('tab is active');
      } else {
        setTabHasFocus(false);
        // console.log('tab is inactive');
      }
    };

    document.addEventListener('visibilitychange', handleFocus);

    return () => {
      window.removeEventListener('visibilitychange', handleFocus);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <UserAddressCtxProvider>
        <WalletBalancesCtxProvider>
          <LiquidationFeesCtxProvider>
            <ExternalMetadataCtxProvider>
              {addresses ? (
                <>
                  <PhishingAlertComponent />
                  <Box
                    w={'full'}
                    maxW={'1920px'}
                    m={'0 auto'}
                    px={'16px'}
                    minH={'100vh'}
                  >
                    <Box
                      d={'none'}
                      position={'absolute'}
                      top={'300px'}
                      left={'0'}
                      opacity={'0.3'}
                      h={'300px'}
                      w={['0', '0', '500px']}
                      filter={'blur(200px)'}
                      pointerEvents={'none'}
                      // bgGradient={
                      //   'radial(farthest-side, hsla(0, 100%, 64%, 1), hsla(0, 100%, 64%, 0))'
                      // }
                      zIndex={'var(--chakra-zIndices-docked)'}
                    />
                    <Box
                      d={'none'}
                      position={'absolute'}
                      bottom={'200px'}
                      right={'100px'}
                      opacity={'0.3'}
                      h={'230px'}
                      w={['0', '0', '350px']}
                      filter={'blur(200px)'}
                      pointerEvents={'none'}
                      // bgGradient={
                      //   'radial(farthest-side, hsla(169, 100%, 46%, 1), hsla(169, 100%, 46%, 0))'
                      // }
                      zIndex={'var(--chakra-zIndices-base)'}
                    />
                    <StrategyMetadataCtxProvider>
                      <MakeMostOf1UsdCtxProvider>
                        <TransactionToasts />
                        <NavigationBar />
                        <br />
                        <GlobalDebtCeilingMessage />
                        {/* <MigratePositionsComponent /> */}
                        {/* <LiquidatablePositionsMessage /> */}
                        <Box pb={['66px', '90px', '90px']}>
                          {params.children}
                          <Outlet />
                        </Box>
                      </MakeMostOf1UsdCtxProvider>
                    </StrategyMetadataCtxProvider>
                  </Box>
                </>
              ) : (
                <NetworkNotSupported />
              )}
            </ExternalMetadataCtxProvider>
          </LiquidationFeesCtxProvider>
        </WalletBalancesCtxProvider>
      </UserAddressCtxProvider>
    </ChakraProvider>
  );
};
