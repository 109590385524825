import {
  Flex,
  Grid,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export function HowStep({
  step,
  title,
  stepImgLight,
  stepImgDark,
  children,
}: React.PropsWithChildren<{
  step: string;
  title: string;
  stepImgLight: any;
  stepImgDark: any;
}>) {
  const { colorMode } = useColorMode();

  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  return (
    <>
      <Grid
        templateColumns={[
          'repeat(1, auto)',
          'repeat(1, auto)',
          'repeat(2, auto)',
        ]}
        templateRows={['repeat(2, auto)', 'repeat(2, auto)', 'repeat(1, auto)']}
        gap={'6'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Flex flexDir={'column'} alignItems={'start'}>
          <Flex>
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              w={'max-content'}
              // m={'auto'}
              p={['4px', '6px', '8px']}
              bg={gradientBg}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                textAlign={'center'}
                w={'max-content'}
                // m={'auto'}
                p={['4px', '6px', '8px']}
                bg={gradientBg}
              >
                <Text
                  fontSize={['14px', '14px', '16px', '18px', '18px']}
                  fontWeight={'500'}
                  color={color}
                >
                  {step}
                </Text>
              </Flex>
            </Flex>
            <Flex
              alignItems={'left'}
              justifyContent={'center'}
              m={'auto'}
              p={['8px', '12px', '16px']}
            >
              <Text
                fontSize={['16px', '16px', '20px', '24px', '24px']}
                fontWeight={'500'}
                color={color}
              >
                {title}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              w={'max-content'}
              // m={'auto'}
              p={['4px', '6px', '8px']}
              bg={'transparent'}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                textAlign={'center'}
                w={'max-content'}
                // m={'auto'}
                p={['4px', '6px', '8px']}
                bg={'transparent'}
              >
                <Text
                  fontSize={['14px', '14px', '16px', '18px', '18px']}
                  fontWeight={'500'}
                  color={color}
                  opacity={'0'}
                >
                  {step}
                </Text>
              </Flex>
            </Flex>
            <Flex
              alignItems={'left'}
              justifyContent={'center'}
              m={'auto'}
              px={['8px', '12px', '16px']}
            >
              {children}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          w={'max-content'}
          m={'auto'}
          p={['8px', '12px', '16px']}
        >
          {colorMode === 'light' ? (
            <Image
              src={stepImgLight}
              alt={'1USD'}
              w={['120px', '160px', '200px']}
              border={'none'}
              boxShadow={'none'}
            />
          ) : (
            <Image
              src={stepImgDark}
              alt={'1USD'}
              w={['120px', '160px', '200px']}
              border={'none'}
              boxShadow={'none'}
            />
          )}
        </Flex>
      </Grid>
    </>
  );
}
