import {
  Container,
  Flex,
  // GridItem,
  Image,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// import { BigNumber } from 'ethers';
// import { CurrencyValue } from '@usedapp/core';
// import { BigNumber } from 'ethers';
import * as React from 'react';
import lines from '../../../assets/lines.svg';
import {
  ParsedPositionMetaRow,
  ParsedStratMetaRow,
} from '../../../chain-interaction/contracts';
// import {
//   useAMMHarvest,
//   useHarvestPartially,
// } from '../../../chain-interaction/transactions';
// import { TransactionErrorDialog } from '../../../components/notifications/TransactionErrorDialog';
// import { LiquidationFeesContext } from '../../../contexts/LiquidationFeesContext';
// import { parseFloatNoNaN } from '../../../utils';

export default function StrategyTokenInformation({
  stratMeta,
}: React.PropsWithChildren<{
  position?: ParsedPositionMetaRow;
  stratMeta: ParsedStratMetaRow;
}>) {
  const calcCRatio = () => {
    return `${Math.round((1 / (stratMeta.borrowablePercent / 100)) * 100)} %`;
  };

  // const { sendAMMHarvest, AMMHarvestState } = useAMMHarvest(
  //   stratMeta.strategyAddress
  // );

  // const tokenFees = React.useContext(LiquidationFeesContext);
  // const interestRate = useInterestRate(BigNumber.from(0)).toNumber() / 100;
  // console.log('interestRate', interestRate.toString());

  // const { sendHarvestPartially, harvestPartiallyState } = useHarvestPartially(
  //   stratMeta.strategyAddress
  // );

  // const stable = useStable();
  // const estimatedHarvestable: BigNumber | undefined = useEstimatedHarvestable(
  //   stratMeta.strategyAddress,
  //   stratMeta.token.address
  // );
  // const harvestLabel = estimatedHarvestable
  //   ? ` $ ${new CurrencyValue(stable, estimatedHarvestable).format({
  //     fixedPrecisionDigits: 0,
  //     useFixedPrecision: true,
  //     suffix: '',
  //     prefix: '',
  //   })}`
  //   : '';

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  return (
    <Container
      variant={'token'}
      position={'relative'}
      minW={'max-content'}
      w={'full'}
    >
      <Image
        src={lines}
        position={'absolute'}
        right={'0'}
        bottom={'0'}
        pointerEvents={'none'}
        zIndex={'var(--chakra-zIndices-base)'}
        opacity={'0.65'}
        d={'none'}
      />
      {/* <TransactionErrorDialog state={AMMHarvestState} title={'AMM Harvest'} />
        <TransactionErrorDialog
          state={harvestPartiallyState}
          title={'Source harvest'}
        /> */}
      <Flex
        flexDir={'column'}
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
        h={'full'}
        p={'20px'}
      >
        <Flex w={'full'}>
          <Text variant={'h200'} color={colorDimmed}>
            Borrow Fee &nbsp; &nbsp;
          </Text>
          <Spacer />
          <Text variant={'bodyLarge'}>
            {/* {stratMeta.mintingFeePercent.toFixed(1)} % */}0 %
          </Text>
        </Flex>

        <Flex w={'full'} mt={'30px'}>
          <Text variant={'h200'} color={colorDimmed}>
            Interest Rate &nbsp; &nbsp;
          </Text>
          <Spacer />
          <Text variant={'bodyLarge'}>
            {/* {interestRate.toFixed(0).toString() ?? ''} % */}3 %
          </Text>
        </Flex>

        <Flex w={'full'} mt={'30px'}>
          <Text variant={'h200'} color={colorDimmed}>
            Max LTV Ratio &nbsp; &nbsp;
          </Text>
          <Spacer />
          <Text variant={'bodyLarge'}>
            {`${5 * Math.round(stratMeta.borrowablePercent / 5)} %`}
          </Text>
        </Flex>

        <Flex w={'full'} mt={'30px'}>
          <Text variant={'h200'} color={colorDimmed}>
            Minimum cRatio &nbsp; &nbsp;
          </Text>
          <Spacer />
          <Text variant={'bodyLarge'}>{calcCRatio()}</Text>
        </Flex>

        <Flex w={'full'} mt={'30px'}>
          <Text variant={'h200'} color={colorDimmed}>
            Liquidation Fee &nbsp; &nbsp;
          </Text>
          <Spacer />
          <Text variant={'bodyLarge'}>
            {/* {tokenFees.get(stratMeta.token.address) + ' %'} */}7 %
          </Text>
        </Flex>
        {/* <Flex w={'full'} mt={'30px'}>
            <Text variant={'h200'} color={colorDimmed}>
              Stability fee
            </Text>
            <Spacer />
            <Text variant={'bodyLarge'}>
              {stratMeta.stabilityFeePercent.toFixed(2)}%
            </Text>
          </Flex> */}
        {/* {stratMeta.yieldType !== YieldType.COMPOUNDING && false ? (
            <Flex w={'full'} mt={'30px'}>
              <Text variant={'h200'} color={colorDimmed}>
                Harvestable
              </Text>
              <Spacer />
              <Button
                variant={'secondary'}
                w={'auto'}
                mt={'-5px'}
                mr={'-20px'}
                onClick={() => {
                  if (stratMeta.yieldType === YieldType.REPAYING) {
                    sendAMMHarvest(stratMeta.token.address);
                  } else if (stratMeta.yieldType === YieldType.COMPOUNDING) {
                    sendHarvestPartially(stratMeta.token.address);
                  }
                }}
              >
                <Text variant={'bodySmall'}>{`Harvest${harvestLabel}`}</Text>
              </Button>
            </Flex>
          ) : undefined} */}
      </Flex>
    </Container>
  );
}
