import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
// import { BigNumber } from 'ethers';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { Column, useTable } from 'react-table';
import {
  ParsedStratMetaRow,
  // useInterestRate,
} from '../../../chain-interaction/contracts';
import CustomTooltip from '../../../components/data-display/CustomTooltip';
import { TokenDescription } from '../../../components/tokens/TokenDescription';
import { hiddenStrategies } from '../../../constants/hidden-strategies';
import { LiquidationFeesContext } from '../../../contexts/LiquidationFeesContext';
import { StrategyMetadataContext } from '../../../contexts/StrategyMetadataContext';
import { TableSearch } from './TableSearch';
import { TableTabs } from './TableTabs';

type Entity = ParsedStratMetaRow & {
  asset: any;
  apy: any;
  token1USDavailable: string;
  // tvlPeg: string;
  totalBorrowed: string;
  liquidationFee: string;
  interestRate: string;
  balance: number;
  ltv: string;
};

export function AllSupportedCollateral() {
  const hiddenTokens: Set<string> = new Set([]);
  const stratMeta: ParsedStratMetaRow[] = Object.values(
    React.useContext(StrategyMetadataContext)
  )
    .filter(
      (stratRows: Record<string, ParsedStratMetaRow>) =>
        !hiddenTokens.has(Object.values(stratRows)[0].token.ticker)
    )
    .map((x: Record<string, ParsedStratMetaRow>) => {
      const tokenAddress = x[Object.keys(x)[0]].token.address;
      const options = Object.values(x);
      const optionsFiltered = Object.values(x).filter(
        (y) =>
          hiddenStrategies[tokenAddress] &&
          !hiddenStrategies[tokenAddress].includes(y.strategyAddress)
      );

      return (
        hiddenStrategies[tokenAddress] ? optionsFiltered : options
      ).reduce((aggStrat, nextStrat) => {
        return {
          ...aggStrat,
          APY: aggStrat.APY > nextStrat.APY ? aggStrat.APY : nextStrat.APY,
          debtCeiling: nextStrat.debtCeiling,
          totalDebt: aggStrat.totalDebt.add(nextStrat.totalDebt),
          tvlInPeg: aggStrat.tvlInPeg.add(nextStrat.tvlInPeg),
        };
      });
    });

  const tokenFees = React.useContext(LiquidationFeesContext);
  // const interestRate = useInterestRate(BigNumber.from(0)).toNumber() / 100;
  const [tableTabFilter, setTableTabFilter] = React.useState<string[]>([]);
  const [searchString, setSearchString] = React.useState('');

  const data = stratMeta
    .filter((meta) => {
      if (tableTabFilter.length === 0) {
        return true;
      } else if (tableTabFilter.includes(meta.token.ticker)) {
        return true;
      } else {
        return false;
      }
    })
    .filter((meta) =>
      searchString.length > 0
        ? meta.token.name.toLowerCase().includes(searchString) ||
          meta.token.ticker.toLowerCase().includes(searchString)
        : true
    )
    .map((meta) => {
      const customAPY =
        meta.underlyingAPY !== undefined
          ? Math.round(meta.underlyingAPY) + Math.round(meta.APY)
          : Math.round(meta.APY);
      return {
        ...meta,
        asset: <TokenDescription token={meta.token} />,
        apy: (
          <>
            {customAPY + ' %'}&nbsp;
            {meta.underlyingAPY ? (
              <CustomTooltip
                label={
                  <>
                    Underlying:{' '}
                    {Math.round(meta.underlyingAPY || meta.APY) + ' %'},
                    <br /> Compounding: {Math.round(meta.APY)} %
                  </>
                }
              />
            ) : null}{' '}
          </>
        ),
        // token1USDavailable: meta.debtCeiling.gt(meta.totalDebt)
        //   ? meta.debtCeiling.sub(meta.totalDebt).format({ suffix: '' })
        //   : '0',
        token1USDavailable: '500,000',
        minColRatio: `${Math.round(
          (1 / (meta.borrowablePercent / 100)) * 100
        )} %`,
        ltv: `${5 * Math.round(meta.borrowablePercent / 5)} %`,
        // tvlPeg: `$ ${meta.tvlInPeg.format({ suffix: '' })}`,
        totalBorrowed: meta.totalDebt.format({ significantDigits: 2 }),
        liquidationFee:
          (tokenFees.get(meta.token.address) ?? 'Loading...') + ' %',
        interestRate:
          // (interestRate.toFixed(0).toString() ?? 'Loading...') + ' %',
          '3 %',
        balance: meta.balance,
      };
    })
    .sort(function (a, b) {
      // if (a.token.ticker < b.token.ticker) {
      if (a.token.address < b.token.address) {
        return -1;
      }

      // if (a.token.ticker > b.token.ticker) {
      if (a.token.address > b.token.address) {
        return 1;
      }

      return 0;
    })
    .sort((a, b) => b.balance - a.balance);

  const border = useColorModeValue('1px solid #1D1D1E', '1px solid #FAFCFD');

  const boxShadow = useColorModeValue(
    'inset 0 0 0 1px hsla(200, 43%, 99%, 0.2)',
    'inset 0 0 0 1px hsla(240, 2%, 12%, 0.2)'
  );

  // const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const invColor = useColorModeValue('#FAFCFD', '#1D1D1E');

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  // const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const invBg = useColorModeValue('#1D1D1E', '#FAFCFD');

  // const gradientBg = useColorModeValue(
  //   'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
  //   'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  // );

  function tooltip(colName: string, label: string) {
    return (
      <Flex>
        {' '}
        {colName} &nbsp;
        <Tooltip
          hasArrow
          label={label}
          bg={invBg}
          boxShadow={boxShadow}
          border={border}
          borderRadius={'0'}
          color={invColor}
          p={'8px 12px'}
          textAlign={'center'}
          w={'max-content'}
          maxW={'100vw'}
          fontSize={['10px', '11px', '12px']}
          fontWeight={'500'}
        >
          <InfoIcon />
        </Tooltip>
      </Flex>
    );
  }

  const columns = React.useMemo<Column<Entity>[]>(
    () => [
      {
        Header: tooltip(
          'COLLATERAL ASSET ',
          'ASSET YOU CAN DEPOSIT AS COLLATERAL TO BORROW 1USD'
        ),
        accessor: 'asset',
      },
      // {
      //   Header: tooltip(
      //     'APY EARNED ',
      //     'THE YIELD YOU EARN ON YOUR DEPOSITED COLLATERAL'
      //   ),
      //   accessor: 'apy',
      // },
      {
        Header: tooltip(
          '1USD AVAILABLE ',
          'TOTAL 1USD THAT CAN STILL BE BORROWED AGAINST THIS ASSET'
        ),
        accessor: 'token1USDavailable',
      },
      // {
      //   Header: tooltip(
      //     'TVL ',
      //     'TOTAL AMOUNT OF THIS ASSET LOCKED IN OUR PROTOCOL, IN US DOLLARS'
      //   ),
      //   accessor: 'tvlPeg',
      // },
      {
        Header: tooltip(
          'MAX LTV ',
          'MAX AMOUNT OF YOUR DEPOSITED VALUE THAT CAN BE EXTRACTED AS 1USD LOANS'
        ),
        accessor: 'ltv',
      },
      // {
      //   Header: tooltip(
      //     'LIQUIDATION FEE ',
      //     'PENALTY FEE PAID IF YOU GET LIQUIDATED'
      //   ),
      //   accessor: 'liquidationFee',
      // },
      {
        Header: tooltip(
          'INTEREST RATE ',
          'YEARLY RATE CHARGED AS A PERCENTAGE OF THE TOTAL 1USD BORROWED'
        ),
        accessor: 'interestRate',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  // console.log('data', data);

  const navigate = useNavigate();
  const goRouteId = (row: any) => {
    // navigate(`/token/${row.original.token.address}`);
    navigate(`/asset/${row.original.token.ticker}`);
  };

  return (
    <>
      <Box textAlign={'center'} m={'50px auto'}>
        <Text fontSize={['24px', '28px', '32px']} fontWeight={'700'}>
          AVAILABLE ASSETS
        </Text>
      </Box>

      <Box w={'full'} maxW={'1024px'} m={'auto'}>
        <Box zIndex={'var(--chakra-zIndices-docked)'} position={'relative'}>
          <Flex
            m={'auto'}
            mb={['0', '0', '-12px', '-12px', '-12px']}
            p={'0'}
            wrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <TableTabs
              setTableTabFilter={setTableTabFilter}
              stratMeta={stratMeta}
            />
            <TableSearch setSearchString={setSearchString} />
          </Flex>
        </Box>
        <Flex
          m={'auto'}
          p={'0'}
          d={['flex', 'flex', 'none', 'none', 'none']}
          flexDir={'column'}
        >
          {rows.map((row) => {
            prepareRow(row);
            const headers = headerGroups
              .map((headerGroup) => {
                return headerGroup.headers.map((column) =>
                  column.render('Header')
                );
              })
              .flat(1);
            return (
              // eslint-disable-next-line
              <Container key={row.id} variant={'token'} mt={'12px'}>
                {row.cells.map((cell, index) => {
                  // eslint-disable-next-line
                  return (
                    <Flex
                      key={'cellMobile' + index}
                      flexDir={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      px={'8px'}
                      py={'16px'}
                      fontSize={{
                        base: '12px',
                        md: '16px',
                      }}
                    >
                      <Box
                        color={colorDimmed}
                        mr={['12px', '16px', '20px']}
                        alignItems={'center'}
                      >
                        {headers[index]}
                      </Box>
                      <Box alignItems={'center'}>{cell.render('Cell')}</Box>
                    </Flex>
                  );
                })}
                <Button
                  variant={'secondary'}
                  as={Link}
                  // to={`/token/${row.original.token.address}`}
                  to={`/asset/${row.original.token.ticker}`}
                  key={row.id}
                  w={'full'}
                >
                  OPEN
                </Button>
              </Container>
            );
          })}
        </Flex>

        <Table
          d={['none', 'none', 'table', 'table', 'table']}
          variant={'dashboard'}
          {...getTableProps()}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line
                  <Td {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Td>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                // eslint-disable-next-line
                <Tr
                  {...row.getRowProps()}
                  // as={Link}
                  // to={`/token/${row.original.token.address}`}
                  onClick={() => goRouteId(row)}
                  d={'table-row'}
                  cursor={'pointer'}
                >
                  {row.cells.map((cell) => {
                    // eslint-disable-next-line
                    return (
                      // eslint-disable-next-line
                      <Td {...cell.getCellProps()}>
                        <div>
                          <div></div>
                          {cell.render('Cell')}
                        </div>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </>
  );
}
