export const strategyNames: Record<string, string> = {
  '0xf9cD4Db17a3FB8bc9ec0CbB34780C91cE13ce767': 'Deposit on Platypus',
  '0x8889Da43CeE581068C695A2c256Ba2D514608F4A': 'Looping on Benqi',
  '0xd0F41b1C9338eB9d374c83cC76b684ba3BB71557': 'Supply sAVAX on Vector',
  '0xc8cEeA18c2E168C6e767422c8d144c55545D23e9': 'Looping on Benqi',
  '0xaAc0F2d0630d1D09ab2B5A400412a4840B866d95': 'Looping on Aave',
  '0x8B414448de8B609e96bd63Dcf2A8aDbd5ddf7fdd': 'Looping on Benqi',
  '0x9f637540149f922145c06e1aa3f38dcDc32Aff5C': 'GLP staking on GMX',
  '0xD390f59705f3F6d164d3C4b2C77d17224FCB033f': 'Compound Reward for STTX LP',
  '0xc08986C33A714545330424fd5Fa132A8110E5E4F': 'yyAVAX Vector',
};
