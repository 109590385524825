import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { TransactionStatus } from '@usedapp/core';
import * as React from 'react';
import { useEffect } from 'react';

export function TransactionErrorDialog({
  // title,
  state,
}: {
  title: string;
  state: TransactionStatus;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (['Fail', 'Exception'].includes(state.status)) {
      onOpen();
    }
  }, [state]);

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2)',
    '1px solid hsla(200, 43%, 99%, 0.2)'
  );

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  // const invBg = useColorModeValue('#1D1D1E', '#FAFCFD');

  // const gradientBg = useColorModeValue(
  //   'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
  //   'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  // );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg={bg}
          borderRadius={'0'}
          position={'absolute'}
          bottom={'-40px'}
          right={'20px'}
          border={border}
        >
          {/* <ModalHeader fontSize={'lg'} fontWeight={'500'}>
            {title} - Transaction Failed
          </ModalHeader> */}
          <ModalHeader fontSize={'lg'} fontWeight={'500'}>
            Transaction Failed
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{state.errorMessage}</ModalBody>
          <ModalFooter>
            <Button variant={'secondary'} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
