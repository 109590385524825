import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';
import { ParsedStratMetaRow } from '../../../../chain-interaction/contracts';
import { ChangeStrategyTable } from './ChangeStrategyTable';

export default function ChangeStrategyModal({
  chooseStrategy,
  stratMeta,
  currentStrategy,
}: {
  chooseStrategy: (strategyToChoose: string) => void;
  stratMeta: Record<string, ParsedStratMetaRow>;
  currentStrategy: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2)',
    '1px solid hsla(200, 43%, 99%, 0.2)'
  );

  return (
    <>
      <Button variant={'secondary'} w={'auto'} mt={'10px'} onClick={onOpen}>
        <Text variant={'bodySmall'}>Change</Text>
      </Button>

      <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={'0'} bg={bg} border={border}>
          <ModalHeader fontSize={'lg'} fontWeight={'500'} mb={'-30px'}>
            Select Strategy
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={['xs', 'sm']}>
            <ChangeStrategyTable
              onClose={onClose}
              stratMeta={stratMeta}
              chooseStrategy={chooseStrategy}
              currentStrategy={currentStrategy}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
