// import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  // Link,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { CurrencyValue, useEthers } from '@usedapp/core';
import axios from 'axios';
import { BigNumber } from 'ethers';
import { getAddress, parseEther } from 'ethers/lib/utils';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  calcLiqPriceFromNum,
  ParsedPositionMetaRow,
  ParsedStratMetaRow,
  useStable,
} from '../../../../chain-interaction/contracts';
import {
  useNativeRepayWithdrawTrans,
  useRepayWithdrawTrans,
} from '../../../../chain-interaction/transactions';
import { TransactionErrorDialog } from '../../../../components/notifications/TransactionErrorDialog';
import WarningMessage from '../../../../components/notifications/WarningMessage';
import { TokenAmountInputField } from '../../../../components/tokens/TokenAmountInputField';
import { TokenDescription } from '../../../../components/tokens/TokenDescription';
import { WNATIVE_ADDRESS } from '../../../../constants/addresses';
import {
  useWalletBalance,
  WalletBalancesContext,
} from '../../../../contexts/WalletBalancesContext';
import { parseFloatCurrencyValue, parseFloatNoNaN } from '../../../../utils';
import { ConfirmPositionModal } from './ConfirmPositionModal';

export default function RepayWithdraw({
  position,
  stratMeta,
}: React.PropsWithChildren<{
  position?: ParsedPositionMetaRow;
  stratMeta: ParsedStratMetaRow;
}>) {
  const { token, usdPrice, borrowablePercent } = stratMeta;
  const { chainId } = useEthers();
  const [data, setData] = useState<{ [x: string]: any }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const stable = useStable();
  const isNativeToken = chainId
    ? getAddress(WNATIVE_ADDRESS[chainId!]) === getAddress(token.address)
    : false;
  const balanceCtx = useContext(WalletBalancesContext);

  const {
    handleSubmit: handleSubMitrepayForm,
    register: registerRepayForm,
    setValue: setValueRepayForm,
    formState: { errors: errorsRepayForm, isSubmitting: isSubmittingRepayForm },
    watch,
  } = useForm();

  const { sendRepayWithdraw, repayWithdrawState } = useRepayWithdrawTrans(
    position && position.trancheId,
    token,
    position?.debt
  );
  // console.log('position.trancheId', position?.trancheId);
  const {
    sendRepayWithdraw: sendNativeRepayWithdraw,
    repayWithdrawState: sendNativeWithdrawState,
  } = useNativeRepayWithdrawTrans(
    position && position.trancheId,
    token,
    position?.debt
  );

  function onRepayWithdraw(data: { [x: string]: any }) {
    // console.log('repay withdraw');
    // console.log(data);
    setData(data);
    onOpen();
  }

  function repayWithdraw() {
    if (isNativeToken) {
      sendNativeRepayWithdraw(
        data!['collateral-withdraw'] || '0',
        data!['1usd-repay'] || '0'
      );
    } else {
      sendRepayWithdraw(
        data!['collateral-withdraw'] || '0',
        data!['1usd-repay'] || '0'
      );
    }
  }

  const walletBalance =
    useWalletBalance(stable.address) ??
    new CurrencyValue(stable, BigNumber.from('0'));

  const repayWithdrawDisabled =
    !position ||
    !position.collateral ||
    (position.collateral.isZero() && position.debt.isZero());

  const [collateralInput, repayInput /*customPercentageInput*/] = watch([
    'collateral-withdraw',
    '1usd-repay',
    // 'custom-percentage',
  ]);

  const extantCollateral =
    position && position.collateral
      ? parseFloatCurrencyValue(position.collateral)
      : 0;
  const totalCollateral = extantCollateral - parseFloatNoNaN(collateralInput);

  const extantDebt =
    position && position.debt && position.debt.gt(position.yield)
      ? parseFloatCurrencyValue(position.debt.sub(position.yield))
      : 0;
  const totalDebt = extantDebt - parseFloatNoNaN(repayInput);

  // const currentPercentage =
  //   totalCollateral > 0 ? (100 * extantDebt) / (totalCollateral * usdPrice) : 0;

  // const percentageStep = Math.max(currentPercentage / 5, 10);
  // const percentageSteps =
  //   10 >= currentPercentage
  //     ? [currentPercentage / 2]
  //     : Array(Math.floor((currentPercentage - 0.5) / percentageStep))
  //       .fill(0)
  //       .map((p, i) => Math.round((p + (i + 1) * percentageStep) / 5) * 5);

  const totalPercentage =
    totalCollateral > 0 && usdPrice > 0
      ? (100 * totalDebt) / (totalCollateral * usdPrice)
      : 0;

  const percentageLabel =
    totalCollateral > 0 ? `${totalPercentage.toFixed(0)} %` : 'LTV %';
  // const percentages = Object.fromEntries(
  //   percentageSteps.map((percentage) => [
  //     `${percentage.toFixed(0)} %`,
  //     totalCollateral - (totalDebt * 100) / (usdPrice * customPercentageInput),
  //   ])
  // );

  // React.useEffect(() => {
  //   if (customPercentageInput) {
  //     setValueRepayForm(
  //       'collateral-withdraw',
  //       totalCollateral - (totalDebt * 100) / (usdPrice * customPercentageInput)
  //     );
  //   } else if (
  //     collateralInput &&
  //     collateralInput > 0 &&
  //     totalPercentage > borrowablePercent
  //   ) {
  //     setValueRepayForm(
  //       '1usd-repay',
  //       (borrowablePercent * totalCollateral * usdPrice) / 100 - extantDebt
  //     );
  //   }
  // }, [
  //   customPercentageInput,
  //   collateralInput,
  //   totalCollateral,
  //   extantDebt,
  //   usdPrice,
  // ]);

  // const buy1USDLink = ``;

  const repayingSttxThanBalance =
    !isNaN(parseFloat(repayInput)) &&
    parseEther(repayInput || '0').gt(walletBalance.value);

  const repayWithdrawButtonDisabled =
    (parseFloatNoNaN(collateralInput) === 0 &&
      parseFloatNoNaN(repayInput) === 0) ||
    totalPercentage > borrowablePercent ||
    (totalCollateral === 0 && totalDebt > 0) ||
    repayingSttxThanBalance;

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const bgOpacity = useColorModeValue(
    'hsla(240, 2%, 12%, 0.08)',
    'hsla(200, 43%, 99%, 0.08)'
  );

  const inputBg = useColorModeValue(
    'hsla(240, 2%, 12%, 0.04)',
    'hsla(200, 43%, 99%, 0.04)'
  );

  // const gradientBg = useColorModeValue(
  //   'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
  //   'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  // );

  const inputStyle = {
    p: '10px 10px 10px 18px',
    bg: inputBg,
    borderRadius: '0',
    justifyContent: 'space-between',
  };

  const showWarning =
    (!(
      parseFloatNoNaN(collateralInput) === 0 &&
      parseFloatNoNaN(repayInput) === 0
    ) &&
      totalPercentage > borrowablePercent) ||
    repayingSttxThanBalance ||
    (totalCollateral === 0 && totalDebt > 0);

  const warningMsgText = repayingSttxThanBalance
    ? 'INPUT LARGER THAN WALLET BALANCE: BUY MORE 1USD'
    : 'REPAY MORE TO KEEP POSITION HEALTHY';

  const residualDebt =
    position && position.debt.gt(position.yield)
      ? position.debt.sub(position.yield)
      : new CurrencyValue(stable, BigNumber.from(0));

  const dangerousPosition =
    totalPercentage > borrowablePercent * 0.92 && totalDebt > 0;
  const liquidatableZone = borrowablePercent;
  const criticalZone = (90 * borrowablePercent) / 100;
  const riskyZone = (80 * borrowablePercent) / 100;
  const healthyZone = (50 * borrowablePercent) / 100;

  const positionHealthColor =
    0.1 > totalDebt
      ? 'blue'
      : totalPercentage > liquidatableZone
      ? 'purple'
      : totalPercentage > criticalZone
      ? 'red'
      : totalPercentage > riskyZone
      ? 'orange'
      : totalPercentage > healthyZone
      ? 'green'
      : 'blue';
  const positionHealth = {
    blue: 'Safe',
    green: 'Healthy',
    orange: 'Risky',
    red: 'Critical',
    purple: 'Liquidatable',
  };

  const [priceBTC, setPriceBTC] = React.useState<number>(0);
  const [priceETH, setPriceETH] = React.useState<number>(0);
  const [priceFSN, setPriceFSN] = React.useState<number>(0);
  const [priceCHNG, setPriceCHNG] = React.useState<number>(0);

  const GetCoinPrices = () => {
    const getPriceBTC = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=BTC'
        );
        const data = response.data;
        setPriceBTC(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceBTC();
      const interval = setInterval(() => {
        getPriceBTC();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceETH = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=ETH'
        );
        const data = response.data;
        setPriceETH(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceETH();
      const interval = setInterval(() => {
        getPriceETH();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceFSN = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=FSN'
        );
        const data = response.data;
        setPriceFSN(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceFSN();
      const interval = setInterval(() => {
        getPriceFSN();
      }, 10000);

      return () => clearInterval(interval);
    }, []);

    const getPriceCHNG = async () => {
      try {
        const response = await axios.get(
          'https://api.coinbase.com/v2/exchange-rates?currency=CHNG'
        );
        const data = response.data;
        setPriceCHNG(data.data.rates.USD);
      } catch (error) {
        console.error(error);
      }
    };

    React.useEffect(() => {
      getPriceCHNG();
      const interval = setInterval(() => {
        getPriceCHNG();
      }, 10000);

      return () => clearInterval(interval);
    }, []);
  };

  GetCoinPrices();

  function numberWithCommas(n: string) {
    return n.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  let assetPrice;

  switch (token.ticker) {
    // case 'WBTC':
    case 'BTC':
      assetPrice = numberWithCommas(
        (Math.floor(priceBTC * 100) / 100).toFixed(2)
      );
      break;
    case 'ETH':
      assetPrice = numberWithCommas(
        (Math.floor(priceETH * 100) / 100).toFixed(2)
      );
      break;
    case 'FSN':
      assetPrice = numberWithCommas(
        (Math.floor(priceFSN * 10000) / 10000).toFixed(4)
      );
      break;
    case 'CHNG':
      assetPrice = numberWithCommas(
        (Math.floor(priceCHNG * 10000) / 10000).toFixed(4)
      );
      break;
    default:
      '...';
  }

  assetPrice === undefined ? (assetPrice = '...') : assetPrice;

  return (
    <>
      <ConfirmPositionModal
        title={'Confirm Repay / Withdraw'}
        isOpen={isOpen}
        onClose={onClose}
        confirm={repayWithdraw}
        body={[
          {
            title: <TokenDescription token={stable} />,
            value: <Text>{data ? data!['1usd-repay'] : ''}</Text>,
          },
          {
            title: <TokenDescription token={stratMeta.token} />,
            value: <Text>{data ? data!['collateral-withdraw'] : ''}</Text>,
          },
          {
            // title: 'At Loan-To-Value [%]',
            value: 'LTV Ratio',
            title: totalPercentage.toFixed(2) + ' %',
          },
        ]}
        dangerous={dangerousPosition}
      />
      <form onSubmit={handleSubMitrepayForm(onRepayWithdraw)}>
        <Flex flexDir={'column'} justify={'start'}>
          <Box w={'full'} textAlign={'start'} mb={'6px'}>
            <WarningMessage message={warningMsgText} isOpen={showWarning}>
              <Text variant={'bodyExtraSmall'} color={colorDimmed}>
                Repay 1USD
              </Text>
            </WarningMessage>
          </Box>
          <HStack {...inputStyle}>
            <TokenDescription token={stable} />
            <TokenAmountInputField
              name={'1usd-repay'}
              max={
                balanceCtx.get(stable.address)?.gt(residualDebt)
                  ? residualDebt
                  : balanceCtx.get(stable.address)
              }
              isDisabled={repayWithdrawDisabled}
              placeholder={'0'}
              registerForm={registerRepayForm}
              setValueForm={setValueRepayForm}
              errorsForm={errorsRepayForm}
              percentage={percentageLabel}
            />
          </HStack>
        </Flex>
        <Flex flexDir={'column'} justify={'start'} mt={'20px'}>
          <Box w={'full'} textAlign={'start'} mb={'6px'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed}>
              Withdraw Collateral
            </Text>
          </Box>
          <HStack {...inputStyle}>
            <TokenDescription token={stratMeta.token} />
            <TokenAmountInputField
              name={'collateral-withdraw'}
              max={position?.collateral}
              isDisabled={repayWithdrawDisabled}
              placeholder={'0'}
              registerForm={registerRepayForm}
              setValueForm={setValueRepayForm}
              errorsForm={errorsRepayForm}
            />
          </HStack>
        </Flex>
        <br />
        <HStack justifyContent={'space-between'}>
          {/* {percentages &&
            Object.entries(percentages).map(([key, value]) => (
              <Button
                variant={'secondary'}
                borderRadius={'full'}
                p={'6px 16px'}
                key={'percentage' + key}
                onClick={() =>
                  setValueRepayForm('collateral-withdraw', value.toFixed(18))
                }
              >
                {key}
              </Button>
            ))} */}

          {/* <NumberInput
            borderRadius={'full'}
            p={'0 16px'}
            bg={bgOpacity}
            border={'none'}
            key={'custom'}
            fontWeight={'500'}
          >
            <NumberInputField
              {...registerRepayForm('custom-percentage')}
              name={'custom-percentage'}
              key={'custom'}
              autoComplete={'off'}
              placeholder={'Custom'}
              variant={'percentage'}
              fontSize={'14px'}
            />
            <InputRightElement w={'auto'} mr={'16px'}>
              %
            </InputRightElement>
          </NumberInput> */}
          <Alert
            status={'info'}
            // justifyContent={'space-between'}
            justifyContent={'end'}
            fontSize={'md'}
            borderRadius={'0'}
            // bg={gradientBg}
            bg={'transparent'}
            // p={'10px 10px 10px 18px'}
            p={'0'}
            // pl={'18px'}
            // pr={'10px'}
          >
            <Flex>
              <AlertIcon />
              <Text
                minW={'max-content'}
                fontWeight={'400'}
                fontSize={['11px', '12px', '13px']}
              >
                YOU NEED 1USD TO REPAY YOUR DEBT
              </Text>
            </Flex>
            <Button
              variant={'secondary'}
              fontSize={['13px', '14px', '15px']}
              ml={'18px'}
              // w={'full'}
              cursor={'not-allowed'}
              // as={Link}
              // href={buy1USDLink}
              target={'_blank'}
              rel={'noreferrer'}
            >
              BUY 1USD
              {/* <ExternalLinkIcon ml={'10px'} /> */}
            </Button>
          </Alert>
        </HStack>
        <br />
        <HStack justifyContent={'space-between'} mt={'10px'}>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              Withdrawal Value
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              $ {(usdPrice * (extantCollateral - totalCollateral)).toFixed(2)}
            </Text>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              Liquidation Price
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              ${' '}
              {calcLiqPriceFromNum(
                borrowablePercent,
                totalDebt,
                totalCollateral
              ).toFixed(2)}
            </Text>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              {positionHealth[positionHealthColor]} Position
            </Text>
            <Box h={'24px'} m={'2px'} d={'flex'} alignItems={'center'}>
              <Progress
                colorScheme={positionHealthColor}
                value={(100 * totalPercentage) / borrowablePercent}
                w={'100px'}
                h={'14px'}
                borderRadius={'0'}
                opacity={'0.65'}
                bg={bgOpacity}
              />
            </Box>
          </VStack>
          <VStack spacing={'2px'} justifyContent={'center'}>
            <Text variant={'bodyExtraSmall'} color={colorDimmed} mb={'5px'}>
              cRatio
            </Text>
            <Text variant={'bodyMedium'} fontWeight={'500'}>
              {totalDebt > 0.01
                ? `${((100 * usdPrice * totalCollateral) / totalDebt).toFixed(
                    2
                  )} %`
                : '∞'}
            </Text>
          </VStack>
        </HStack>
        <HStack mt={'30px'} spacing={'8px'} justifyContent={'center'}>
          {/* <Text variant={'h300'} color={colorDimmed}>
            Price:
          </Text> */}
          {/* <Text variant={'bodySmall'}>{`1 ${
            token.ticker
          } = $ ${usdPrice.toFixed(2)}`}</Text> */}
          <Text
            variant={'bodySmall'}
          >{`1 ${token.ticker} = $ ${assetPrice}`}</Text>
        </HStack>

        <TransactionErrorDialog
          state={repayWithdrawState}
          title={'Repay | Withdraw'}
        />
        <TransactionErrorDialog
          state={sendNativeWithdrawState}
          title={'Repay | Withdraw'}
        />

        <Button
          type={'submit'}
          variant={repayWithdrawButtonDisabled ? 'disabled' : 'primary'}
          h={'56px'}
          w={'full'}
          mt={'10px'}
          isLoading={isSubmittingRepayForm}
          // isDisabled={repayWithdrawButtonDisabled}
          isDisabled={true}
        >
          <Text variant={'bodyMedium'} fontWeight={'600'}>
            REPAY & WITHDRAW
          </Text>
        </Button>
      </form>
    </>
  );
}
