import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogCloseButton,
  // useColorMode,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';
// import { mode } from '@chakra-ui/theme-tools';

export default function NetworkNotSupported() {
  // const { toggleColorMode } = useColorMode();
  const { onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const { deactivate } = useEthers();

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');
  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2)',
    '1px solid hsla(200, 43%, 99%, 0.2)'
  );
  // const boxShadow = useColorModeValue(
  //   'inset 0 0 0 1px hsla(240, 2%, 12%, 0.1)',
  //   'inset 0 0 0 1px hsla(200, 43%, 99%, 0.1)'
  // );

  return (
    <>
      <AlertDialog
        motionPreset={'slideInBottom'}
        isOpen={true}
        isCentered
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <div>
          <AlertDialogOverlay />
          <AlertDialogContent
            bg={bg}
            border={border}
            // boxShadow={boxShadow}
            borderRadius={'0'}
            pt={'4'}
          >
            <AlertDialogHeader textAlign={'center'}>
              Network Not Supported
            </AlertDialogHeader>
            <AlertDialogCloseButton
              ref={cancelRef}
              d={'none'}
              onClick={onClose}
            />
            <AlertDialogBody>
              <Text textAlign={'center'}>
                Please, use your wallet to connect to the appropriate network.
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                colorScheme={'red'}
                ml={'3'}
                d={'none'}
                onClick={deactivate}
              >
                Disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </div>
      </AlertDialog>
    </>
  );
}
