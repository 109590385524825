import { Box, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import token1USD from '../../../assets/crypto/1USD.svg';
import tokenSTTX from '../../../assets/crypto/STTX.svg';
import { FeaturedAssets } from './FeaturedAssets';
import { FontAnimation } from './FontAnimation';
import { HomeButtons } from './HomeButtons';
import { HowSection } from './HowSection';
import { JoinCommunity } from './JoinCommunity';
import { TokenSection } from './TokenSection';

export function HomeContent() {
  useEffect(() => {
    const onPageLoad = () => {
      FontAnimation();
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  // const { colorMode } = useColorMode();

  // const border = useColorModeValue(
  //   '2px solid hsla(240, 2%, 12%, 0.8)',
  //   '2px solid hsla(200, 43%, 99%, 0.8)'
  // );

  // const tokenBorder = useColorModeValue(
  //   '4px solid hsla(200, 43%, 99%, 1)',
  //   '4px solid hsla(240, 2%, 12%, 1)'
  // );

  // const tokenBorderSmall = useColorModeValue(
  //   '2px solid hsla(200, 43%, 99%, 1)',
  //   '2px solid hsla(240, 2%, 12%, 1)'
  // );

  // const boxShadow = useColorModeValue(
  //   '0 0 0 4px hsla(240, 2%, 12%, 0.1)',
  //   '0 0 0 4px hsla(200, 43%, 99%, 0.1)'
  // );

  // const boxShadowSmall = useColorModeValue(
  //   '0 0 0 2px hsla(240, 2%, 12%, 0.1)',
  //   '0 0 0 2px hsla(200, 43%, 99%, 0.1)'
  // );

  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  // const gradientBg = useColorModeValue(
  //   'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
  //   'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  // );

  return (
    <>
      <Box
        d={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        w={'full'}
        maxW={'90vw'}
        m={'auto'}
      >
        <Text id={'title'}>BE LIQUID</Text>
      </Box>

      <Box w={'90vw'} maxW={'640px'} m={'auto'} mt={'20px'}>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['16px', '20px', '24px', '28px', '32px']}
            fontWeight={'300'}
          >
            WITH <strong>STOUT</strong>. THE DECENTRALIZED OMNICHAIN BORROWING
            PROTOCOL.
          </Text>
        </Flex>
      </Box>

      <Box w={'90vw'} maxW={'800px'} m={'auto'} mt={'20px'}>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['12px', '14px', '16px', '18px', '20px']}
            fontWeight={'400'}
            color={colorDimmed}
          >
            Use your favorite assets as collateral to borrow 1USD, a
            permissionless, censorship-resistant stablecoin.
          </Text>
        </Flex>
      </Box>

      <HomeButtons
        borrowButtonDisplay={'flex'}
        mintButtonDisplay={'flex'}
        arrowsDisplay={'flex'}
      />

      <TokenSection
        title={'1USD'}
        subtitle={'| Over-Collateralized Decentralized Stablecoin'}
        firstDescTitle={'FINANCIAL FREEDOM WITH NO VOLATILITY'}
        firstDesc={
          'Pegged to the U.S. Dollar, all 1USD in circulation is directly backed by excess collateral of selected assets. Generate 1USD on your terms, instantly.'
        }
        secondDescTitle={'A PRICE-STABLE CURRENCY THAT YOU CONTROL'}
        secondDesc={
          'Built to be stable, decentralized, tamper-proof, and freeze-free, 1USD is the stablecoin of Stout Protocol, a perpetual CDP system.'
        }
        tokenImg={token1USD}
        tokenAlt={'1USD'}
        firstImgDisplay={['flex', 'flex', 'flex']}
        secondImgDisplay={['none', 'none', 'none']}
      />

      <HomeButtons
        borrowButtonDisplay={'flex'}
        mintButtonDisplay={'none'}
        arrowsDisplay={'none'}
        mTop={['-10px', '-20px', '-30px']}
      />

      <TokenSection
        title={'STTX'}
        subtitle={'| Stout Protocol Ingenious Governance Token'}
        firstDescTitle={'UNSTOPPABLE WEALTH STRONGHOLD'}
        firstDesc={
          'STTX is a new kind of financial instrument that accrues intrinsic monetary value with the passage of time.'
        }
        secondDescTitle={'DECENTRALIZED GOVERNANCE, BUILT TO GROW'}
        secondDesc={
          'Due to its innovative mechanics, STTX operates as a self-sustaining deflationary governance token with an ever-increasing floor price.'
        }
        tokenImg={tokenSTTX}
        tokenAlt={'STTX'}
        firstImgDisplay={['flex', 'flex', 'none']}
        secondImgDisplay={['none', 'none', 'flex']}
      />

      <HomeButtons
        borrowButtonDisplay={'none'}
        mintButtonDisplay={'flex'}
        arrowsDisplay={'none'}
        mTop={['-10px', '-20px', '-30px']}
      />

      <HowSection />

      <FeaturedAssets />

      <JoinCommunity />

      <Box
        alignItems={'center'}
        justifyContent={'center'}
        w={'max-content'}
        m={'auto'}
        mt={'60px'}
      >
        <Link
          as={ReactLink}
          to={'/terms'}
          fontSize={['10px', '11px', '12px']}
          textAlign={'center'}
          color={colorDimmed}
          fontWeight={'400'}
          _hover={{ color: color, fontWeight: '500' }}
        >
          TERMS OF SERVICE
        </Link>
      </Box>
    </>
  );
}
