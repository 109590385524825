import {
  Box,
  Container,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import discordDark from '../../assets/discord-dark.svg';
import discordLight from '../../assets/discord-light.svg';
import docsDark from '../../assets/docs-dark.svg';
import docsLight from '../../assets/docs-light.svg';
import dotsDark from '../../assets/horizontal-dots-dark.svg';
import dotsLight from '../../assets/horizontal-dots-light.svg';
import mirrorDark from '../../assets/mirror-dark.svg';
import mirrorLight from '../../assets/mirror-light.svg';
import telegramDark from '../../assets/telegram-dark.svg';
import telegramLight from '../../assets/telegram-light.svg';
import twitterDark from '../../assets/twitter-dark.svg';
import twitterLight from '../../assets/twitter-light.svg';
import { MenuLink } from './MenuLink';

export default function MenuOptions() {
  const { colorMode } = useColorMode();

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.3)',
    '1px solid hsla(200, 43%, 99%, 0.3)'
  );

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  const [is1Hovered, setIs1Hovered] = React.useState(false);
  const [is2Hovered, setIs2Hovered] = React.useState(false);
  const [is3Hovered, setIs3Hovered] = React.useState(false);
  const [is4Hovered, setIs4Hovered] = React.useState(false);
  const [is5Hovered, setIs5Hovered] = React.useState(false);

  function enter1() {
    setIs1Hovered(true);
  }
  function leave1() {
    setIs1Hovered(false);
  }

  function enter2() {
    setIs2Hovered(true);
  }
  function leave2() {
    setIs2Hovered(false);
  }

  function enter3() {
    setIs3Hovered(true);
  }
  function leave3() {
    setIs3Hovered(false);
  }

  function enter4() {
    setIs4Hovered(true);
  }
  function leave4() {
    setIs4Hovered(false);
  }

  function enter5() {
    setIs5Hovered(true);
  }
  function leave5() {
    setIs5Hovered(false);
  }

  const styles = {
    enter: {
      background: bg,
      fontWeight: '600',
    },

    leave: {
      background: 'transparent',
      fontWeight: '400',
    },
  };

  return (
    <Box>
      <Menu gutter={12}>
        {colorMode === 'light' ? (
          <MenuButton
            variant={'secondary'}
            as={IconButton}
            aria-label={'Options'}
            icon={<Image src={dotsLight} alt={'Options'} />}
          />
        ) : (
          <MenuButton
            variant={'secondary'}
            as={IconButton}
            aria-label={'Options'}
            icon={<Image src={dotsDark} alt={'Options'} />}
          />
        )}

        <MenuList
          bg={bg}
          border={border}
          boxShadow={'none'}
          borderRadius={'0'}
          p={'0'}
          minW={'max-content'}
        >
          <Container
            bg={gradientBg}
            variant={'token'}
            boxShadow={'none'}
            p={'0'}
          >
            <MenuLink
              link={'https://twitter.com/stout_fi'}
              title={'Twitter'}
              imgLight={twitterLight}
              imgDark={twitterDark}
              enter={enter1}
              leave={leave1}
              style={is1Hovered ? styles.enter : styles.leave}
              opacity={is1Hovered ? 1 : 0.65}
            />

            <MenuLink
              link={'https://t.me/stout_fi'}
              title={'Telegram'}
              imgLight={telegramLight}
              imgDark={telegramDark}
              enter={enter2}
              leave={leave2}
              style={is2Hovered ? styles.enter : styles.leave}
              opacity={is2Hovered ? 1 : 0.65}
            />

            <MenuLink
              link={'https://discord.gg/cpztJe9Qzx'}
              title={'Discord'}
              imgLight={discordLight}
              imgDark={discordDark}
              enter={enter3}
              leave={leave3}
              style={is3Hovered ? styles.enter : styles.leave}
              opacity={is3Hovered ? 1 : 0.65}
            />

            <MenuLink
              link={
                'https://mirror.xyz/0xdD2db463a1111aa4c1C9927009e2701D4042F836'
              }
              title={'Mirror'}
              imgLight={mirrorLight}
              imgDark={mirrorDark}
              enter={enter4}
              leave={leave4}
              style={is4Hovered ? styles.enter : styles.leave}
              opacity={is4Hovered ? 1 : 0.65}
            />

            <MenuLink
              // link={'https://stout.gitbook.io/docs'}
              title={'Documentation'}
              imgLight={docsLight}
              imgDark={docsDark}
              enter={enter5}
              leave={leave5}
              style={is5Hovered ? styles.enter : styles.leave}
              opacity={is5Hovered ? 1 : 0.65}
              cursor={'not-allowed'}
            />
          </Container>
        </MenuList>
      </Menu>
    </Box>
  );
}
