import { Link } from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';

export function NavigationLink({
  page,
}: React.PropsWithChildren<{
  page: string;
}>) {
  const location = useLocation();

  return (
    <>
      <Link
        as={ReactLink}
        to={`/${page}`}
        variant={location.pathname === `/${page}` ? 'headerActive' : 'header'}
        textTransform={'uppercase'}
      >
        {page}
      </Link>
    </>
  );
}
