import {
  CheckCircleIcon,
  CopyIcon,
  ExternalLinkIcon,
  SmallAddIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  // ChainId,
  CurrencyValue,
  shortenAddress,
  useEthers,
} from '@usedapp/core';
// import { Avalanche } from '@usedapp/core';
import { BigNumber } from 'ethers';
import * as React from 'react';
import { useContext } from 'react';
import { useAddresses, useStable } from '../../chain-interaction/contracts';
import { getTokenFromAddress } from '../../chain-interaction/tokens';
import { UserAddressContext } from '../../contexts/UserAddressContext';
import { WalletBalancesContext } from '../../contexts/WalletBalancesContext';

type Props = {
  isOpen: any;
  onClose: any;
};

export default function AccountModal({ isOpen, onClose }: Props) {
  const { deactivate } = useEthers();
  const account = useContext(UserAddressContext);
  const { chainId } = useEthers();
  // const _chainId = chainId === ChainId.Hardhat ? ChainId.Avalanche : chainId;
  const stable = useStable();
  const balanceCtx = React.useContext(WalletBalancesContext);
  const sttxToken = getTokenFromAddress(chainId, useAddresses().SttxToken);

  const walletBalance =
    balanceCtx.get(stable.address) ||
    new CurrencyValue(stable, BigNumber.from('0'));

  const sttxBalance =
    balanceCtx.get(sttxToken.address) ||
    new CurrencyValue(sttxToken, BigNumber.from('0'));

  // const explorerLink = account
  //   ? getExplorerAddressLink(account, _chainId!)
  //   : '';

  // const explorerLink = account ? Avalanche.getExplorerAddressLink(account) : '';
  const explorerLink = account ? 'https://fsnscan.com/address/' + account : '';

  function handleDeactivateAccount() {
    deactivate();
    onClose();
  }

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2)',
    '1px solid hsla(200, 43%, 99%, 0.2)'
  );

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  const initialText = 'Copy Address';
  const [buttonText, setButtonText] = React.useState(initialText);

  function handleClick() {
    setButtonText('Copied');

    setTimeout(() => {
      setButtonText(initialText);
    }, 1000);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'md'}>
      <ModalOverlay />
      <ModalContent borderRadius={'0'} bg={bg} border={border}>
        <Box bg={gradientBg}>
          <ModalHeader px={'16px'} fontSize={'lg'} fontWeight={'500'}>
            <Text>Account</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={['2px 8px', '2px 16px']}>
            <Box borderRadius={'0'} border={border} p={['8px', '12px']}>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                mb={'12px'}
              >
                <Text color={colorDimmed} fontSize={['12px', '13px', '14px']}>
                  Connected with MetaMask
                </Text>
                <Button
                  variant={'secondary'}
                  size={'sm'}
                  fontSize={['11px', '12px', '13px']}
                  onClick={handleDeactivateAccount}
                >
                  Disconnect
                </Button>
              </Flex>
              <Flex alignItems={'center'} mt={'8px'} mb={'12px'}>
                <Text color={color} fontSize={'xl'} fontWeight={'600'} ml={'2'}>
                  {account && `${shortenAddress(account)}`}
                </Text>
              </Flex>
              <Flex alignItems={'center'} mt={'8px'} mb={'12px'}>
                <Text d={'inline-flex'} ml={'8px'}>
                  {walletBalance?.format({ significantDigits: 2 })}
                </Text>
                <SmallAddIcon
                  border={border}
                  borderRadius={'full'}
                  cursor={'not-allowed'}
                  ml={'10px'}
                  _hover={{ bg: bg }}
                />
                <Text d={'inline-flex'} mx={'12px'}>
                  /
                </Text>
                <Text d={'inline-flex'}>
                  {sttxBalance?.format({ significantDigits: 2 })}
                </Text>
                <SmallAddIcon
                  border={border}
                  borderRadius={'full'}
                  cursor={'not-allowed'}
                  ml={'10px'}
                  _hover={{ bg: bg }}
                />
              </Flex>
              <Flex
                alignItems={'center'}
                justifyContent={'space-between'}
                maxW={'300px'}
                m={'16px 8px'}
              >
                <Button
                  variant={'link'}
                  fontSize={['11px', '12px', '13px']}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(account ?? '')
                      .then(handleClick)
                  }
                >
                  {buttonText === initialText && <CopyIcon mr={'8px'} />}
                  {buttonText === 'Copied' && <CheckCircleIcon mr={'8px'} />}
                  {buttonText}
                </Button>
                <Link
                  variant={'basic'}
                  fontSize={['11px', '12px', '13px']}
                  href={`${explorerLink}`}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <ExternalLinkIcon mr={'8px'} />
                  <Text textDecor={'none'}>View on Explorer</Text>
                </Link>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter justifyContent={'end'} p={'6'}>
            <Text
              color={color}
              textAlign={'left'}
              fontWeight={'500'}
              fontSize={['12px', '13px', '14px']}
            >
              Your transactions will appear here...
            </Text>
          </ModalFooter>
        </Box>
      </ModalContent>
    </Modal>
  );
}
