import { Flex, Image, Tab, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

export function FeaturedAssetTab({
  ticker,
  name,
  img,
}: React.PropsWithChildren<{
  ticker: string;
  name: string;
  img: any;
}>) {
  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const tokenBorder = useColorModeValue(
    '1.5px solid hsla(200, 43%, 99%, 0.8)',
    '1.5px solid hsla(240, 2%, 12%, 0.8)'
  );

  const tokenBoxShadow = useColorModeValue(
    '0 0 0 1.5px hsla(240, 2%, 12%, 0.2)',
    '0 0 0 1.5px hsla(200, 43%, 99%, 0.2)'
  );

  const borderColor = useColorModeValue(
    'hsla(240, 2%, 12%, 0.8)',
    'hsla(200, 43%, 99%, 0.8)'
  );

  const borderColorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.4)',
    'hsla(200, 43%, 99%, 0.4)'
  );

  return (
    <>
      <Tab
        bg={'none'}
        border={'2px solid transparent'}
        boxShadow={'none'}
        p={['16px', '20px', '24px']}
        w={'full'}
        minW={'max-content'}
        // justifyContent={'center'}
        justifyContent={'start'}
        _hover={{
          borderBottomColor: borderColorDimmed,
        }}
        _focus={{ boxShadow: 'none' }}
        _active={{
          borderBottomColor: borderColor,
        }}
        _selected={{
          _hover: {
            borderBottomColor: borderColor,
          },
          borderBottomColor: borderColor,
          fontWeight: '600',
        }}
      >
        <Image
          h={['28px', '35px', '42px']}
          border={tokenBorder}
          boxShadow={tokenBoxShadow}
          borderRadius={'full'}
          src={img}
          alt={name}
        />
        <Flex
          flexDir={'column'}
          alignItems={'start'}
          ml={['10px', '11px', '12px']}
          textTransform={'uppercase'}
        >
          <Text fontSize={['12px', '16px', '20px']} color={color}>
            {ticker}
          </Text>
          <Text
            d={'none'}
            fontSize={['10px', '11px', '12px']}
            color={colorDimmed}
          >
            {name}
          </Text>
        </Flex>
      </Tab>
    </>
  );
}
