// import { Button, HStack, Image, Text, useMediaQuery } from '@chakra-ui/react';
import {
  Button,
  HStack,
  Text,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { CurrencyValue, useEthers } from '@usedapp/core';
import { BigNumber } from 'ethers';
import * as React from 'react';
import { useContext } from 'react';
// import colorDot from '../../assets/green-dot.svg';
import { useAddresses, useStable } from '../../chain-interaction/contracts';
import { getTokenFromAddress } from '../../chain-interaction/tokens';
import { MakeMostOf1UsdContext } from '../../contexts/MakeMostOf1UsdContext';
import { UserAddressContext } from '../../contexts/UserAddressContext';
import { WalletBalancesContext } from '../../contexts/WalletBalancesContext';
import { useConnectWallet } from '../../utils';

type Props = {
  handleOpenModal: any;
};

export function UserAddressComponent({ handleOpenModal }: Props) {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1920px)');
  const { chainId } = useEthers();
  const { onConnect } = useConnectWallet();
  const { MostOf1UsdPopover } = React.useContext(MakeMostOf1UsdContext);
  const account = useContext(UserAddressContext);
  const stable = useStable();
  const balanceCtx = React.useContext(WalletBalancesContext);
  const sttxToken = getTokenFromAddress(chainId, useAddresses().SttxToken);

  const walletBalance =
    balanceCtx.get(stable.address) ||
    new CurrencyValue(stable, BigNumber.from('0'));

  const sttxBalance =
    balanceCtx.get(sttxToken.address) ||
    new CurrencyValue(sttxToken, BigNumber.from('0'));

  function handleConnectWallet() {
    onConnect();
  }

  // const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const bgOpacity = useColorModeValue(
    'hsla(240, 2%, 12%, 0.08)',
    'hsla(200, 43%, 99%, 0.08)'
  );

  return (
    <HStack
      // spacing={'18px'}
      bg={bgOpacity}
      p={'4px'}
      borderRadius={'0'}
    >
      <MostOf1UsdPopover>
        <HStack alignContent={'center'}>
          {walletBalance &&
          sttxBalance &&
          !sttxBalance.isZero() &&
          !walletBalance.isZero() &&
          isLargerThan1280 ? (
            <Text fontSize={['12px', '14px', '14px']} textAlign={'center'}>
              {walletBalance?.format({ significantDigits: 2 })} /{' '}
              {sttxBalance?.format({ significantDigits: 2 })}
            </Text>
          ) : // <Image src={colorDot} />
          null}
        </HStack>
      </MostOf1UsdPopover>
      <Button
        variant={'primary'}
        m={'0 !important'}
        onClick={account ? handleOpenModal : handleConnectWallet}
      >
        {account ? (
          <Text fontSize={['12px', '14px', '14px']} fontWeight={'600'}>
            {account &&
              `${account.slice(0, 6)}...${account.slice(
                account.length - 4,
                account.length
              )}`}
          </Text>
        ) : (
          <Text fontSize={['11px', '12px', '13px', '14px', '15px']}>
            CONNECT WALLET
          </Text>
        )}
      </Button>
    </HStack>
  );
}
