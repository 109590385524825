import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import * as React from 'react';
import { ParsedStratMetaRow } from '../../../chain-interaction/contracts';
// import { deprecatedTokenList } from '../../../constants/deprecated-token-list';

export function TableTabs({
  setTableTabFilter,
}: // stratMeta,
{
  setTableTabFilter: React.Dispatch<React.SetStateAction<string[]>>;
  stratMeta: ParsedStratMetaRow[];
}) {
  const spotTickers = ['BTC', 'ETH', 'FSN', 'STTX', 'CHNG'];
  const futuresTickers = ['TF–USDT', 'TF–USDC'];
  const liquidityTickers = [
    'TF–USDT∕USDT',
    'TF–USDC∕USDC',
    'TF–DAI∕DAI',
    // 'TF–ETH∕ETH',
    'TF–BUSD∕BUSD',
  ];

  // const futuresTickers = [];

  // const liquidityTickers = [];

  // const singleAssetTickers = stratMeta
  //   .filter((meta) => !meta.token.ticker.includes('/'))
  //   .map((meta) => meta.token.ticker);

  // const lpTickers = stratMeta
  //   .filter((meta) => meta.token.ticker.includes('/'))
  //   .map((meta) => meta.token.ticker);

  // const deprecatedTickers = stratMeta
  //   .filter((meta) => deprecatedTokenList.includes(meta.token.address))
  //   .map((meta) => meta.token.ticker);

  return (
    <Box>
      <Tabs
        variant={'main'}
        onChange={(index) => {
          if (index === 0) {
            setTableTabFilter([]);
            // } else if (index === 1) {
            //   setTableTabFilter(stableTickers);
          } else if (index === 1) {
            setTableTabFilter(spotTickers);
          } else if (index === 2) {
            setTableTabFilter(futuresTickers);
          } else if (index === 3) {
            setTableTabFilter(liquidityTickers);
          }
        }}
      >
        <TabList>
          <Tab fontSize={['11px', '12px', '13px']}>ALL</Tab>
          <Tab fontSize={['11px', '12px', '13px']}>SPOT</Tab>
          <Tab fontSize={['11px', '12px', '13px']}>FUTURES</Tab>
          <Tab fontSize={['11px', '12px', '13px']}>LIQUIDITY</Tab>
        </TabList>
      </Tabs>
    </Box>
  );
}
