export function FontAnimation() {
  let maxDist;
  let mouse = { x: 0, y: 0 };
  let cursor = {
    x: window.innerWidth,
    y: window.innerHeight,
  };

  Math.dist = function (a, b) {
    let dx = b.x - a.x;
    let dy = b.y - a.y;
    return Math.sqrt(Math.pow(dx, 2), Math.pow(dy, 2));
  };

  window.addEventListener('mousemove', function (e) {
    cursor.x = e.clientX;
    cursor.y = e.clientY;
  });

  window.addEventListener(
    'touchmove',
    function (e) {
      let t = e.touches[0];
      cursor.x = t.clientX;
      cursor.y = t.clientY;
    },
    {
      passive: false,
    }
  );

  let Char = function (container, char) {
    let span = document.createElement('span');
    span.setAttribute('data-char', char);
    span.innerText = char;
    container.appendChild(span);
    this.getDist = function () {
      this.pos = span.getBoundingClientRect();
      return Math.dist(mouse, {
        x: this.pos.x + this.pos.width / 2,
        y: this.pos.y + this.pos.height / 2,
      });
    };
    this.getAttr = function (dist, min, max) {
      let wght = max - Math.abs((max * dist) / maxDist);
      return Math.max(min, wght + min);
    };
    this.update = function (args) {
      let dist = this.getDist();
      this.wdth = args.wdth ? ~~this.getAttr(dist, 5, 200) : 100;
      this.wght = args.wght ? ~~this.getAttr(dist, 300, 700) : 300;
      this.alpha = args.alpha ? this.getAttr(dist, 0, 1).toFixed(2) : 1;
      this.ital = args.ital ? this.getAttr(dist, 0, 1).toFixed(2) : 0;
      this.draw();
    };
    this.draw = function () {
      let style = '';
      style += 'opacity: ' + this.alpha + ';';
      style +=
        "font-variation-settings: 'wght' " +
        this.wght +
        ", 'wdth' " +
        this.wdth +
        ", 'ital' " +
        this.ital +
        ';';
      span.style = style;
    };
    return this;
  };

  let VFont = function () {
    this.scale = false;
    this.flex = false;
    this.alpha = false;
    this.stroke = false;
    this.width = false;
    this.weight = true;
    this.italic = false;
    let title,
      str,
      chars = [];

    this.init = function () {
      title = document.getElementById('title');
      str = title.innerText;
      title.innerHTML = '';
      for (let i = 0; i < str.length; i++) {
        let _char = new Char(title, str[i]);
        chars.push(_char);
      }
      this.set();
      window.addEventListener('resize', this.setSize.bind(this));
    };

    this.set = function () {
      title.className = '';
      title.className += this.flex ? ' flex' : '';
      title.className += this.stroke ? ' stroke' : '';
      this.setSize();
    };

    this.setSize = function () {
      let fontSize = window.innerWidth / ((str.length / 9) * 10);
      title.style = 'font-size: ' + fontSize + 'px;';
      if (this.scale) {
        let scaleY = (
          window.innerHeight / title.getBoundingClientRect().height
        ).toFixed(2);
        let lineHeight = scaleY * 1;
        title.style =
          'font-size: ' +
          fontSize +
          'px; transform: scale(1,' +
          scaleY +
          '); line-height: ' +
          lineHeight +
          'em;';
      }
    };

    this.animate = function () {
      mouse.x += (cursor.x - mouse.x) / 20;
      mouse.y += (cursor.y - mouse.y) / 20;
      requestAnimationFrame(this.animate.bind(this));
      this.render();
    };

    this.render = function () {
      maxDist = title.getBoundingClientRect().width / 5;
      for (let i = 0; i < chars.length; i++) {
        chars[i].update({
          wght: this.weight,
          wdth: this.width,
          ital: this.italic,
          alpha: this.alpha,
        });
      }
    };
    this.init();
    this.animate();
    return this;
  };

  new VFont();
}
