import {
  Container,
  Flex,
  GridItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { ParsedStratMetaRow } from '../../../../chain-interaction/contracts';
import CustomTooltip from '../../../../components/data-display/CustomTooltip';

export default function CollateralAPY({
  stratMeta,
  chosenStrategy,
}: {
  stratMeta: Record<string, ParsedStratMetaRow>;
  chosenStrategy: string;
}) {
  const multipleOptions =
    stratMeta[chosenStrategy].selfRepayingAPY > 0 &&
    stratMeta[chosenStrategy].compoundingAPY > 0;
  const textVariant = 'bodySmall';
  const underlyingAPY = stratMeta[chosenStrategy].underlyingAPY;
  const customAPY =
    stratMeta[chosenStrategy].underlyingAPY !== undefined
      ? stratMeta[chosenStrategy].underlyingAPY! + stratMeta[chosenStrategy].APY
      : stratMeta[chosenStrategy].APY;

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  return (
    <GridItem rowSpan={[12, 12, 1]} colSpan={[12, 12, 1]} d={'none'}>
      {/* <GridItem colSpan={2}> */}
      <Container variant={'token'} p={['20px 30px']}>
        <Flex
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          h={'full'}
        >
          <Flex>
            <Text variant={textVariant} color={colorDimmed}>
              Collateral APY &nbsp;
            </Text>
            {underlyingAPY ? (
              <CustomTooltip
                label={
                  <>
                    Underlying: {underlyingAPY} %<br /> Compounding:{' '}
                    {stratMeta[chosenStrategy].APY.toFixed(2)} %
                  </>
                }
              />
            ) : null}
          </Flex>
          <Text variant={'bodyExtraLarge'}>
            {' '}
            <strong>{customAPY.toFixed(2)} %</strong>
          </Text>
          {multipleOptions ? (
            <Flex flexDir={'column'} alignItems={'center'}>
              <Flex flexDir={'row'}>
                <Text color={color}>
                  {stratMeta[chosenStrategy].compoundingAPY.toFixed(2)} %
                </Text>
                <Text variant={'bodySmall'} color={colorDimmed}>
                  &nbsp;
                  {stratMeta[chosenStrategy].underlyingStrategyName ??
                    'Compounding'}
                </Text>
              </Flex>
              <Flex flexDir={'row'}>
                <Text color={color}>
                  {stratMeta[chosenStrategy].selfRepayingAPY.toFixed(2)} %
                </Text>
                <Text variant={'bodySmall'} color={colorDimmed}>
                  &nbsp;{'Self-Repaying'}
                </Text>
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Container>
    </GridItem>
  );
}
