// import { Progress } from '@chakra-ui/react';
// import { Box, Image, Spinner, Text } from '@chakra-ui/react';
import { Box, Image, useColorMode } from '@chakra-ui/react';
import React from 'react';
import loadingDarkImg from '../assets/loading-dark.svg';
import loadingLightImg from '../assets/loading-light.svg';
import {
  StrategyMetadata,
  // useIsolatedStrategyMetadata,
  useLegacyIsolatedStrategyMetadata,
} from '../chain-interaction/contracts';

export const StrategyMetadataContext = React.createContext<StrategyMetadata>(
  {}
);

export function StrategyMetadataCtxProvider({
  children,
}: React.PropsWithChildren<any>) {
  // const stratMeta = useIsolatedStrategyMetadata();
  const stratMeta = useLegacyIsolatedStrategyMetadata();

  const { colorMode } = useColorMode();

  return (
    <StrategyMetadataContext.Provider value={stratMeta}>
      {Object.values(stratMeta).length > 0 ? (
        children
      ) : (
        // <Progress isIndeterminate />
        <Box
          d={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          h={'100vh'}
          w={'full'}
          m={'0'}
          p={'0'}
        >
          {/* <Spinner /> */}
          {/* <Text ml={'10px'}>Loading...</Text> */}

          {colorMode === 'light' ? (
            <Image src={loadingLightImg} />
          ) : (
            <Image src={loadingDarkImg} />
          )}
        </Box>
      )}
    </StrategyMetadataContext.Provider>
  );
}
