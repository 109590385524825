import { Link } from '@chakra-ui/react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export function NavigationLinkDisabled({
  page,
}: React.PropsWithChildren<{
  page: string;
}>) {
  const location = useLocation();

  return (
    <>
      <Link
        // as={ReactLink}
        // to={`/${page}`}
        variant={location.pathname === `/${page}` ? 'headerActive' : 'header'}
        textTransform={'uppercase'}
        cursor={'not-allowed'}
      >
        {page}
      </Link>
    </>
  );
}
