import {
  Box,
  Flex,
  Grid,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export function TokenSection({
  title,
  subtitle,
  firstDescTitle,
  firstDesc,
  secondDescTitle,
  secondDesc,
  tokenImg,
  tokenAlt,
  firstImgDisplay,
  secondImgDisplay,
}: {
  title: string;
  subtitle: string;
  firstDescTitle: string;
  firstDesc: string;
  secondDescTitle: string;
  secondDesc: string;
  tokenImg: string;
  tokenAlt: string;
  firstImgDisplay: any;
  secondImgDisplay: any;
}) {
  const tokenBorder = useColorModeValue(
    '4px solid hsla(200, 43%, 99%, 1)',
    '4px solid hsla(240, 2%, 12%, 1)'
  );

  const boxShadowSmall = useColorModeValue(
    '0 0 0 2px hsla(240, 2%, 12%, 0.1)',
    '0 0 0 2px hsla(200, 43%, 99%, 0.1)'
  );

  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  const gradientBg = useColorModeValue(
    'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
    'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  );

  return (
    <>
      <Box py={['12px', '16px', '24px']}>
        <Box w={'90vw'} maxW={'500px'} m={'auto'}>
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <Text
              fontSize={['16px', '20px', '24px', '28px', '32px']}
              fontWeight={'400'}
            >
              <strong>{title}</strong> {subtitle}
            </Text>
          </Flex>
        </Box>
        <Box
          w={'90vw'}
          maxW={'1024px'}
          m={'auto'}
          py={['20px', '30px', '40px']}
        >
          <Grid
            templateColumns={[
              'repeat(1, auto)',
              'repeat(1, auto)',
              'repeat(2, auto)',
              'repeat(2, auto)',
              'repeat(2, auto)',
            ]}
            templateRows={['repeat(2, auto)']}
            gap={'6'}
          >
            <Flex
              d={firstImgDisplay}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              borderRadius={'full'}
              w={'max-content'}
              m={'auto'}
              p={['8px', '12px', '16px']}
              bg={gradientBg}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                textAlign={'center'}
                borderRadius={'full'}
                w={'max-content'}
                m={'auto'}
                p={['8px', '12px', '16px']}
                bg={gradientBg}
              >
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  textAlign={'center'}
                  borderRadius={'full'}
                  w={'max-content'}
                  m={'auto'}
                  p={['8px', '12px', '16px']}
                  bg={gradientBg}
                >
                  <Image
                    src={tokenImg}
                    alt={tokenAlt}
                    h={['120px', '180px', '240px']}
                    w={'full'}
                    maxW={['120px', '180px', '240px']}
                    borderRadius={'full'}
                    // border={[tokenBorderSmall, tokenBorder]}
                    border={'none'}
                    // boxShadow={[boxShadowSmall, boxShadow]}
                    boxShadow={'none'}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Grid
              templateColumns={['repeat(1, auto)']}
              templateRows={['repeat(2, auto)']}
              gap={'3'}
              m={'auto'}
            >
              <Flex
                flexDir={'column'}
                alignItems={'start'}
                justifyContent={'center'}
                textAlign={'left'}
                maxW={'500px'}
                ml={'auto'}
                p={['8px 12px', '12px 16px', '18px 24px']}
                border={tokenBorder}
                boxShadow={boxShadowSmall}
                bg={gradientBg}
              >
                <Text
                  fontSize={['10px', '12px', '14px', '16px', '18px']}
                  fontWeight={'500'}
                  color={color}
                  mb={['6px', '8px', '10px']}
                >
                  {firstDescTitle}
                </Text>
                <Text
                  fontSize={['10px', '12px', '14px', '16px', '18px']}
                  fontWeight={'400'}
                  color={colorDimmed}
                >
                  {firstDesc}
                </Text>
              </Flex>
              <Flex
                flexDir={'column'}
                alignItems={'start'}
                justifyContent={'center'}
                textAlign={'left'}
                maxW={'500px'}
                ml={'auto'}
                p={['8px 12px', '12px 16px', '18px 24px']}
                border={tokenBorder}
                boxShadow={boxShadowSmall}
                bg={gradientBg}
              >
                <Text
                  fontSize={['10px', '12px', '14px', '16px', '18px']}
                  fontWeight={'500'}
                  color={color}
                  mb={['6px', '8px', '10px']}
                >
                  {secondDescTitle}
                </Text>
                <Text
                  fontSize={['10px', '12px', '14px', '16px', '18px']}
                  fontWeight={'400'}
                  color={colorDimmed}
                >
                  {secondDesc}
                </Text>
              </Flex>
            </Grid>
            <Flex
              d={secondImgDisplay}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              borderRadius={'full'}
              w={'max-content'}
              m={'auto'}
              p={['8px', '12px', '16px']}
              bg={gradientBg}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                textAlign={'center'}
                borderRadius={'full'}
                w={'max-content'}
                m={'auto'}
                p={['8px', '12px', '16px']}
                bg={gradientBg}
              >
                <Flex
                  alignItems={'center'}
                  justifyContent={'center'}
                  textAlign={'center'}
                  borderRadius={'full'}
                  w={'max-content'}
                  m={'auto'}
                  p={['8px', '12px', '16px']}
                  bg={gradientBg}
                >
                  <Image
                    src={tokenImg}
                    alt={tokenAlt}
                    h={['120px', '180px', '240px']}
                    w={'full'}
                    maxW={['120px', '180px', '240px']}
                    borderRadius={'full'}
                    // border={[tokenBorderSmall, tokenBorder]}
                    border={'none'}
                    // boxShadow={[boxShadowSmall, boxShadow]}
                    boxShadow={'none'}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
