import {
  Container,
  // Flex,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  ParsedPositionMetaRow,
  ParsedStratMetaRow,
} from '../../../../chain-interaction/contracts';
import DepositBorrow from './DepositBorrow';
import RepayWithdraw from './RepayWithdraw';

export default function EditPosition({
  position,
  stratMeta,
}: React.PropsWithChildren<{
  position?: ParsedPositionMetaRow;
  stratMeta: ParsedStratMetaRow;
}>) {
  return (
    <GridItem
      rowSpan={[12, 12, 2]}
      colSpan={[1, 1, 1]}
      w={'full'}
      maxW={'max-content'}
    >
      <Container variant={'token'} minW={'max-content'}>
        <Tabs variant={'primary'}>
          <TabList>
            <Tab fontSize={['11px', '12px', '13px']}>BORROW</Tab>
            <Tab fontSize={['11px', '12px', '13px']}>REPAY</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DepositBorrow position={position} stratMeta={stratMeta} />
            </TabPanel>
            <TabPanel>
              <RepayWithdraw position={position} stratMeta={stratMeta} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </GridItem>
  );
}
