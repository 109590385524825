import {
  Alert,
  AlertIcon,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export default function WarningMessage(
  props: React.PropsWithChildren<{ isOpen: boolean; message: string }>
) {
  const { message, isOpen } = props;

  const border = useColorModeValue(
    '1px solid hsla(240, 2%, 12%, 0.2)',
    '1px solid hsla(200, 43%, 99%, 0.2)'
  );

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  // const invColor = useColorModeValue('#FAFCFD', '#1D1D1E');

  const bg = useColorModeValue('#FAFCFD', '#1D1D1E');

  const simpleBg = useColorModeValue('#FAFCFD', '#1D1D1E');

  // const invBg = useColorModeValue('#1D1D1E', '#FAFCFD');

  // const gradientBg = useColorModeValue(
  //   'linear-gradient(0deg, hsla(240, 2%, 12%, 0.04) 0%, hsla(240, 2%, 12%, 0.02) 100%)',
  //   'linear-gradient(0deg, hsla(200, 43%, 99%, 0.04) 0%, hsla(200, 43%, 99%, 0.02) 100%)'
  // );

  return (
    <>
      <Box>
        <Popover
          autoFocus={false}
          returnFocusOnClose={true}
          placement={'right'}
          isOpen={isOpen}
        >
          <PopoverTrigger>{props.children}</PopoverTrigger>
          <PopoverContent
            maxW={'max-content'}
            mt={'94px'}
            borderRadius={'0'}
            bg={bg}
            border={border}
          >
            <PopoverArrow bg={simpleBg} />
            <PopoverBody bg={bg} p={'2px'}>
              <Alert
                color={color}
                status={'warning'}
                fontWeight={'400'}
                fontSize={['11px', '12px', '13px']}
              >
                <AlertIcon />
                {message}
              </Alert>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
}
