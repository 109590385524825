import { Box, Flex, Grid, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';
import { SocialLink } from './SocialLink';
import twitterDark from '../../../assets/twitter-dark.svg';
import twitterLight from '../../../assets/twitter-light.svg';
import telegramLight from '../../../assets/telegram-light.svg';
import telegramDark from '../../../assets/telegram-dark.svg';
import discordLight from '../../../assets/discord-light.svg';
import discordDark from '../../../assets/discord-dark.svg';

export function JoinCommunity() {
  const color = useColorModeValue(
    'hsla(240, 2%, 12%, 1)',
    'hsla(200, 43%, 99%, 1)'
  );

  const colorDimmed = useColorModeValue(
    'hsla(240, 2%, 12%, 0.65)',
    'hsla(200, 43%, 99%, 0.65)'
  );

  return (
    <>
      <Box
        w={'90vw'}
        maxW={['400px', '650px', '750px', '850px', '1000px']}
        m={'auto'}
        mt={['60px', '80px', '100px']}
      >
        <Flex
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
        >
          <Text
            fontSize={['16px', '20px', '24px', '28px', '32px']}
            fontWeight={'700'}
            color={color}
            mb={['6px', '8px', '10px']}
          >
            JOIN THE COMMUNITY
          </Text>
          <Text
            fontSize={['10px', '12px', '14px', '16px', '18px']}
            fontWeight={'400'}
            color={colorDimmed}
          >
            Expand your knowledge on Stout, engage in conversations with the
            team and fellow community members, and play a role in influencing
            the future of decentralized finance.
          </Text>
        </Flex>
      </Box>
      <Box w={'90vw'} maxW={'1024px'} m={'auto'} mt={['10px', '20px', '30px']}>
        <Grid
          templateColumns={['repeat(3, auto)']}
          templateRows={['repeat(1, auto)']}
          mt={['20px', '30px', '40px']}
          gap={['1', '2', '3']}
        >
          <SocialLink
            link={'https://twitter.com/stout_fi'}
            title={'Twitter'}
            imgLight={twitterLight}
            imgDark={twitterDark}
          />
          <SocialLink
            link={'https://t.me/stout_fi'}
            title={'Telegram'}
            imgLight={telegramLight}
            imgDark={telegramDark}
          />
          <SocialLink
            link={'https://discord.gg/cpztJe9Qzx'}
            title={'Discord'}
            imgLight={discordLight}
            imgDark={discordDark}
          />
        </Grid>
      </Box>
    </>
  );
}
