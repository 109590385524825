import {
  Flex,
  Image,
  Link,
  MenuItem,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export function MenuLink({
  link,
  title,
  imgLight,
  imgDark,
  enter,
  leave,
  style,
  opacity,
  cursor,
}: React.PropsWithChildren<{
  link?: string;
  title: string;
  imgLight: any;
  imgDark: any;
  enter: any;
  leave: any;
  style: any;
  opacity: any;
  cursor?: string;
}>) {
  const { colorMode } = useColorMode();

  const color = useColorModeValue('#1D1D1E', '#FAFCFD');

  // const colorDimmed = useColorModeValue(
  //   'hsla(240, 2%, 12%, 0.65)',
  //   'hsla(200, 43%, 99%, 0.65)'
  // );

  return (
    <Link variant={'basic'} href={link} target={'_blank'} rel={'noreferrer'}>
      <MenuItem
        justifyContent={'space-between'}
        p={'8px 16px'}
        pr={'12px'}
        onMouseEnter={enter}
        onMouseLeave={leave}
        style={style}
        color={color}
        opacity={opacity}
        textTransform={'uppercase'}
        cursor={cursor}
      >
        <Flex>
          <Text fontSize={['11px', '12px', '13px']}>{title}</Text>
        </Flex>

        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          ml={'12px'}
          h={'20px'}
          w={'30px'}
        >
          {colorMode === 'light' ? (
            <Image
              h={'18px'}
              src={imgLight}
              alt={title}
              _hover={{ opacity: '1' }}
            />
          ) : (
            <Image
              h={'18px'}
              src={imgDark}
              alt={title}
              _hover={{ opacity: '1' }}
            />
          )}
        </Flex>
      </MenuItem>
    </Link>
  );
}
